import React, { useState, useEffect } from "react";
import {
  Grid,
  FormControl,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  InputAdornment,
  IconButton,
  Card,
  CardContent,
} from "@mui/material";
import { PATTERNS } from "../../component/validetion";
import { validateInput } from "../../component/validetionUtility";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";
import { postFormData, postJsonData } from "../../Services/AxoisInterceptorr";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { MarqetingLogoSidebar } from "../../component/IconsImport";
import { useUser } from "../../contexts/UserDataStore";
import CryptoJS from "crypto-js";
import Maintenance from "../../pages/error/MaintenceError";

const Verify = () => {
  const [isEmail, setIsEmail] = useState(false);
  const [email, setEmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [otpEntryHidden, setotpEntryHidden] = useState(true);

  const [showPasswordToggle, setShowPasswordToggle] = useState("");
  const [error, setError] = useState({ type: null, message: null });

  const [formData, setFormData] = useState({
    phone: {
      value: "",
      isValid: true,
    },
    password: {
      value: "",
      isValid: true,
    },
  });
  const [loading, setLoading] = useState(false);

  const { setUser } = useUser();
  const [open, setOpen] = useState(false);
  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    // const isValid = regEx ? validateInput(value, regEx) : true;
    const isValid = value.trim() === "" || validateInput(value, regEx);
    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
  };

  const navigate = useNavigate();
  const ERROR_TYPES = {
    API_ERROR: "API_ERROR",
    NETWORK_ERROR: "NETWORK_ERROR",
  };
  const handleLogin = (e) => {
    e.preventDefault();

    const isFormValid =
      formData.phone.isValid &&
      // formData.password.isValid &&
      formData.phone.value.trim() !== "";
    // formData.password.value.trim() !== "";
    if (isFormValid && !loading) {
      setLoading(true);
      const data = {
        mobileNumber: formData.phone.value,
        pin: formData.password.value,
        // password: formData.password.value,
      };
      postJsonData(
        VisitorEndpoint.BRAND_LOGIN,
        data,
        () => {},
        (res) => {
          if (res) {
            if (res && res.status === 200) {
              localStorage.setItem("mqttoken", res.data.data.accessToken);

              // Extract role and admin details from the response
              const role = res.data.data.role;
              const brandId = res.data.data.brandId;
              const admin = res.data.data.admin;
              const brandName = res.data.data.brandName;

              // Encrypt
              const encryptedAdmin = CryptoJS.AES.encrypt(
                JSON.stringify(admin),
                process.env.REACT_APP_SECRET_KEY
              ).toString();
              const encryptedRole = CryptoJS.AES.encrypt(
                role,
                process.env.REACT_APP_SECRET_KEY
              ).toString();
              const encryptedBrandId = CryptoJS.AES.encrypt(
                brandId,
                process.env.REACT_APP_SECRET_KEY
              ).toString();
              const encryptedBrandName = CryptoJS.AES.encrypt(
                brandName,
                process.env.REACT_APP_SECRET_KEY
              ).toString();

              // Store in local storage
              localStorage.setItem("mqttoken", res.data.data.accessToken);
              localStorage.setItem("userRole", encryptedRole);
              localStorage.setItem("userDeatils", encryptedAdmin);
              localStorage.setItem("userid", encryptedBrandId);
              localStorage.setItem("name", encryptedBrandName);
              setUser(res.data.data.admin);

              // Extract role from the response
              // const role = res.data.data.role;
              // // Set the user role in local storage dynamically
              // localStorage.setItem("role", role);
              // localStorage.setItem("user", JSON.stringify(res.data.data.admin));
              toast.success(res.data.message);
              if (role === "Brand") {
                navigate("/brand/");
              } else if (role === "Influncer") {
                navigate("/influencer/");
              }
              // navigate("/brand");
            }
            setLoading(false);
          }
          setError({ type: null, message: null });
        },
        (error) => {
          let errorType = ERROR_TYPES.NETWORK_ERROR;
          let errorMessage = "";
          if (error.response && error.response.data) {
            errorType = ERROR_TYPES.API_ERROR;
            errorMessage = error.response.data.message;
          }
          setError({ type: errorType, message: errorMessage });
          if (errorType === ERROR_TYPES.NETWORK_ERROR) {
            navigate("/maintenance");
          } else {
            toast.error(error.response.data.message);
          }
          setLoading(false);
        }
      );
    } else {
      console.log("Form data is not valid.");
      setLoading(false);
    }
  };

  const handleClickShowPassword = () => {
    setShowPasswordToggle(!showPasswordToggle);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const clsx = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };


  return (
    <>

      <section class="bg-primary bg-cover">
        <div class="flex flex-col min-h-screen items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div class="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 ">
            <a
              href="#"
              class="flex items-center justify-center mb-0 mt-10 text-2xl font-semibold text-gray-900"
            >
              <img
                class="w-[200px] h-[50px] mr-2"
                src={MarqetingLogoSidebar}
                alt="logo"
              />
            </a>

            <div class="p-6 pt-0 space-y-4 md:space-y-6 sm:p-8 sm:pt-0">
              <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                 Login For Brand
              </h1>
              <form class="space-y-4 md:space-y-6" onSubmit={handleLogin}>
                <div>
                  <label
                    for="phone"
                    class="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Mobile Number
                  </label>
                  <div className="flex flex-col rounded-lg bg-white">
                    <input
                      type="number"
                      name="phone"
                      id="phone"
                      class="bg-gray-50 border  text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      placeholder="Enter Number"
                      required
                      onChange={(e) => {
                        setValues(e, "phone", PATTERNS.MOBILE);
                      }}
                    />
                    {formData.phone.isValid || (
                      <p className="input_text_error">
                        *Please enter your valid phone number.
                      </p>
                    )}
                  </div>
                </div>

                <div>
                  <label
                    for="password"
                    class="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Password
                  </label>
                  <div className="flex flex-col">
                    <div className="flex flex-row rounded-lg bg-white">
                      <input
                        type={showPasswordToggle ? "text" : "password"}
                        name="password"
                        id="password"
                        placeholder="Enter Password"
                        // disabled
                        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-l-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 disabled:bg-slate-200 disabled:cursor-not-allowed"
                        required
                        onChange={(e) => {
                          setValues(e, "password", null);
                        }}
                      />

                      <div class=" flex justify-center items-center w-20 border border-gray-300 border-l-0 rounded-r-lg rounded-l-none   bg-white hover:bg-primary-700 ">
                        <InputAdornment>
                          <IconButton
                            sx={{ color: "#163e6e" }}
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {showPasswordToggle ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      </div>
                    </div>
                    {/* {formData.password.isValid || (
                          <>
                            <p className="input_text_error">
                              *Please enter your password.
                            </p>
                          </>
                        )} */}
                  </div>
                </div>
                {/* </div> */}
                <div class="flex items-center justify-end">
                  <p
                    onClick={() => navigate("/forgot")}
                    class="text-sm font-medium text-primary-600 cursor-pointer"
                  >
                    Forgot Password
                  </p>
                </div>
                <button 
                  type="submit" disabled={loading}  
                  class="w-full text-white bg-primary hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                > {loading ? "Loading..." : "Login"}
              
                </button>
                <div className="flex flex-col gap-2">
                <p class="text-sm text-center font-bold text-black-800 ">
                  Or</p>
                <p class="text-sm text-center font-light text-black-200 ">
                  If you are an Influencer? {''}
                  <a
                    onClick={() => navigate("/influencer/login")}
                    class="font-medium cursor-pointer text-primary-600 hover:underline"
                  >
                    Login here
                  </a>
                </p>
                </div>
                {/* <p class="text-sm font-light text-black-200 ">
                  Don’t have an account yet?{" "}
                  <a
                    onClick={() => navigate("/register")}
                    class="font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Sign up
                  </a>
                </p> */}
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Verify;

import React, { useEffect, useState } from "react";
import { Box, Button, TablePagination } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTable from "../../component/tabel/DataTabel";
import ShortingTable from "../../component/tabel/ShortingTabel";
import {
  useGetBrandCampaign,
  useGetCampaign,
  useGetPlatform,
  useGetStatus,
} from "../../component/customHooks/customHook";

import SortPopupComponent from "../../common/sort/sortPopupComponent";
import ActiveLastBreadcrumb from "../../component/breadcrumb/ActiveLastBreadcrumb";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import CampaignView from "../../component/viewdetails/CampaignView";
import FilterPopupComponent from "../../common/filter/filterPopup";
import { useDispatch, useSelector } from "react-redux";
import { setCampaignFilter } from "../../store/FilterSlice";
import CryptoJS from "crypto-js";
import LocalMallIcon from '@mui/icons-material/LocalMall';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
const sortArray = [


  {
    sortOrder: "desc",
    sortField: "updatedAt",
    sortName: "Updated Date (Dsc)",
  },
  {
    sortOrder: "asc",
    sortField: "campaignStartDate",
    sortName: "Campaign Start Date",
  },


];

const CampaignComponent = () => {
  const dispatch = useDispatch();
  const filterParam = useSelector((state) => state.filter.campaignFilter);

  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [influencerDrawerOpen, setInfluencerDrawerOpen] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [editState, setEditState] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const [perPageLimit, setPerPageLimit] = useState(25);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [decryptedBrandId, setDecryptedBrandId] = useState(null);
  // console.log(userRole);

  useEffect(() => {
    const encryptedBrandId = localStorage.getItem("userid");
    if (encryptedBrandId) {
      const bytes = CryptoJS.AES.decrypt(encryptedBrandId,
        process.env.REACT_APP_SECRET_KEY
      );
      const decryptedId = bytes.toString(CryptoJS.enc.Utf8);
      setDecryptedBrandId(decryptedId);
    }
  }, []);
  // console.log(decryptedBrandId);
  const navigate = useNavigate();
  const columns = [
    { id: "sno", label: "S.No.", minWidth: 200 },
    { id: "campaignnamebrandstats", label: "Campaign Name", minWidth: 200 },
    // { id: "productname", label: "Product Name", minWidth: 200 },
    { id: "campaigntype", label: "Platform", minWidth: 200 },
    // { id: "noOfContent", label: "Requested of Reviews", minWidth: 200 },
    { id: "noOfContent", label: "No. of Reviews", minWidth: 200 },
    { id: "pendingpayment", label: "Pending Payment", minWidth: 200 },
    { id: "status", label: "Status", minWidth: 150 },
  ];

  const { allstatus, loading, totalPages, totalCount } = useGetBrandCampaign(
    decryptedBrandId && decryptedBrandId,
    currentPage,
    perPageLimit,
    sortField,
    sortOrder,
    filterParam,
    triggerRefresh
  );
  const { allstatus:newFilterData} = useGetBrandCampaign(
    decryptedBrandId
  );
  const camapignFilter = newFilterData?.map(item => ({ id: item._id, name: item.campaignName }));

  const status = useGetStatus("", "", "Campaign");
  const campaignPlatformFilter = newFilterData?.map(campaign => 
    campaign.campaignPlatform.map(platform => ({
      platformId: platform._id,
      platformName: platform.platform
    }))
  );
  const flattenedCampaignPlatformFilter = campaignPlatformFilter.flat();
  const uniquePlatformData = flattenedCampaignPlatformFilter.reduce((unique, item) => {
    return unique.some(u => u.platformId === item.platformId) ? unique : [...unique, item];
  }, []);
  

  const realData = allstatus.map((item) => {
    let campaignType = "";
    if (Array.isArray(item.campaignPlatform)) {
      campaignType = item.campaignPlatform
        .map((platformObj) => platformObj.platform || "")
        .join(", ");
    }
    let productNames = "";
    if (Array.isArray(item.product)) {
      productNames = item.product
        .map((productObj) =>
          productObj.productId ? productObj.productId.productName : ""
        )
        .filter((productName) => productName)
        .join(",");
    }

    return {
      id: item._id,
      sno: item.sno,
      campaignnamebrandstats: item.campaignName,
      pendingpayment: item.pendingPayment,
      // productname: productNames,
      campaigntype: campaignType,
      status: item.status && item.status[0].status,
      noOfContent: item.noOfContent,
    };
  });
  let filters = [
    {
      label: "Campaign Name",
      urlParam: "campaignname",
      values: camapignFilter?.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    },
    {
      label: "Campaign Platform",
      urlParam: "campaignplatform",
      values: uniquePlatformData?.map((item) => ({
        label: item?.platformName,
        value: item?.platformId,
      })),
    },
    {
      label: "Status",
      urlParam: "status",
      values: status.allstatus.map((item) => ({
        label: item.status,
        value: item._id,
      })),
    },
  ];
  const refreshData = () => {
    setTriggerRefresh(!triggerRefresh);
  };
  const actions = [
    {
      icon: VisibilityIcon,
      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
        setInfluencerDrawerOpen(true);
      },
    },
    {
      icon: ShoppingBagIcon,
      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);

        const campaignId = row.id; 
        const stats = {};
      
        navigate(`/brand/order`, { state: { campaignId } });
      },
    },
  ];

  const selectSort = (sortFieldValue, sortOrderValue) => {
    setSortField(sortFieldValue);
    setSortOrder(sortOrderValue);
  };
  const handleOpenRemove = () => {
    setEditState(null);
    setOpenRemoveModal(true);
  };

  const handleRowsPerPageChange = (event) => {
    setPerPageLimit(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  const startIndex = (currentPage - 1) * perPageLimit + 1;
  const endIndex = Math.min(startIndex + perPageLimit - 1, totalCount);
  const handleChangePage = (event, newPage) => {
    if (newPage > currentPage - 1 && endIndex < totalCount) {
      setCurrentPage(newPage + 1);
    } else if (newPage < currentPage - 1 && startIndex > 1) {
      setCurrentPage(newPage + 1);
    }
  };

  const setParam = (value) => {
    if (filterParam !== value) {
      dispatch(setCampaignFilter(value));
      setCurrentPage(1);
      setTriggerRefresh((prev) => !prev);
    }
  };

  return (
    <>
      <CampaignView
        influencerDrawerOpen={influencerDrawerOpen}
        setInfluencerDrawerOpen={setInfluencerDrawerOpen}
        rowData={selectedRowData}
      />

      <div className="container_fluid_new">
        <div className="container_new">
          <div>
            <ActiveLastBreadcrumb
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Campaign", href: "/brand/campaign" },
              ]}
            />
          </div>
          <div className="brands_container">
            <div className="brands_content">
              <p>Campaign</p>
              <span>{totalCount}&nbsp;Total Campaign</span>
            </div>
            {/* <div>
              <CustomButton
                onClick={() => navigate("/campaign/add")}
                label="Add Campaign"
              />
            </div> */}
          </div>
          <div className="campaign_filter_style">
            <SortPopupComponent
              sortFunction={selectSort}
              sortArray={sortArray}
            />
            <FilterPopupComponent
              filters={filters}
              onChange={setParam}
              isOpen={isOpen}
              filterKey="campaignFilter"
            />
          </div>
          <ShortingTable
            columns={columns}
            loading={loading}
            data={realData}
            actions={actions}
            onClickLink={(row) => {
              const selectedData = allstatus.find(
                (item) => item._id === row.id
              );
              setSelectedRowData(selectedData);
              navigate(`/campaign/stats/${row.id}`);
            }}
            movertShowIcon={false}
          />

          <div style={{paddingTop:loading?  "40px": 0}}>
          <TablePagination
            rowsPerPageOptions={[20, 25, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={perPageLimit}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPageChange}
            labelDisplayedRows={({ from, to, count }) =>
              `${startIndex}-${endIndex} of ${count}`
            }
            sx={{
              fontFamily: '"Outfit", sans-serif',
              fontWeight: 600,
              fontSize: "14px",
              color: "#000",
            }}
          />
          </div>
        </div>
      </div>
    </>
  );
};
export default CampaignComponent;





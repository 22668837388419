import * as React from 'react';
import { NavLink } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';

const styles = {
  link: {
    color: '#ccc',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  activeLink: {
    color: '#ffffff',
    textDecoration: 'none',
   
  },
};

export default function ActiveLastBreadcrumb({ links }) {
  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb"   sx={{
          color: "#fff",
          fontSize: "18px",
          fontFamily: '"Outfit", sans-serif',
        }}>
        {links.map((link, index) => (
          <NavLink
            key={index}
            to={link.href}
            aria-current={index === links.length - 1 ? 'page' : undefined}
            style={index === links.length - 1 ? styles.activeLink : styles.link}
          >
            {link.name}
          </NavLink>
        ))}
      </Breadcrumbs>
    </div>
  );
}


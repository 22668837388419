import React, { createContext, useContext, useEffect, useState } from 'react';
import CryptoJS from "crypto-js";

// Create a context
const UserContext = createContext();

// Create a provider component
export const UserProvider = ({ children }) => {
// In your UserProvider component
const [user, setUser] = useState(null);

// Decrypt user data when component mounts
useEffect(() => {
  const encryptedUser = localStorage.getItem('userDeatils');
  if (encryptedUser) {
    const bytes  = CryptoJS.AES.decrypt(encryptedUser, process.env.REACT_APP_SECRET_KEY);
    const decryptedUser = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUser(decryptedUser);
  }
}, []);


  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the user context
export const useUser = () => useContext(UserContext);

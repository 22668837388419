import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
} from "@mui/material";
import { toast } from "react-toastify";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { postJsonData, putJsonData } from "../../Services/AxoisInterceptorr";
import { useGetStatus } from "../customHooks/customHook";
import { CustomRequiredLabel } from "../../common/styledCcomponents/styledInput";
import { DescriptionInput } from "../../common/styledCcomponents/StyledMenu";
import SingleSelect from "../../common/select/SingleSelect";
import { validateInput } from "../validetionUtility";
import { SmallButton } from "../coustombutton/SmallButton";
import CloseIcon from "@mui/icons-material/Close";

const InfluencerPopup = ({
  open,
  onClose,
  onFormSubmit,
  editState,
  endpoint,
 

  onSuccess,
}) => {

  const [formData, setFormData] = useState({
    name: "",
  });
  const [formSubmitted, setFormSubmitted] = useState(false);




  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    let isValid = true;
    if (regEx) {
      isValid = value.trim() === "" || validateInput(value, regEx);
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
  
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    const isFormValid =
    formData.name?.value;
    if (isFormValid) {
      const data = {
        influencerName: formData.name.value,
      };
  
      onFormSubmit(data);
      // toast.success("Influencer Added successfully!");
      onClose();
      onSuccess && onSuccess();
      setFormData({});
     
    
    }else {
      console.log("Form data is not valid.");
    }
  }

  return (
    <>

          <Modal open={open} onClose={onClose}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: {xs:"90%", sm: "90%", md: "400px" },
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                background: "#FFFFFF",
                padding: "50px",
                borderRadius: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <CloseIcon
                  onClick={onClose}
                  sx={{
                    color: "red",
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    marginBottom: "10px",
                  }}
                />
              </div>
              <h2 className="admin_note_heading">
                Add Reviewer Influencer
              </h2>
              <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
              <Grid
                container
                spacing={2}
                sx={{ marginBottom: "20px", marginTop: "20px" }}
              >
           
                <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>Enter Reviewer Name</CustomRequiredLabel>

                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="name"
                      type="text"
                      placeholder="Enter Reviewer Name"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "name", null);
                      }}
                    />
                  </FormControl>
                  {formSubmitted && !formData.name?.value && (
                            <p className="input_text_error">
                              *Please select reviewer name.
                            </p>
                          )}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <SmallButton
                  onClick={handleSubmit}
                  label="Submit"
                  width="100%"
                />
              </Grid>
            </Box>
          </Modal>
    
    </>
  );
};

export default InfluencerPopup;

import React from "react";
import AddOrder from "../component/add/AddOrder";
import DashboardComponent from "../pages/dashboard/DashboardComponent";
import LoginForm from "../pages/LoginAdmin";
import CampaignStatsView from "../component/viewdetails/CampaignStatsView";
import CampaignComponent from "../pages/forbrand/CampaignComponent";
import OrderComponent from "../pages/forbrand/OrderComponent";
import MarqOrderComponent from "../pages/formarqinfluncers/MarqOrderComponent";
import AddReviewMarq from "../pages/formarqinfluncers/AddReviewMarq";
import MarqDashboardComponent from "../pages/dashboard/MarqDashboardComponent";
import Unauthorized from "../pages/error/UnauthorizedPage";
import PageNotFound from "../pages/error/PageNotFound";
import Verify from "../component/verify/loginComponent";
import ForgotComponent from "../component/verify/ForgotComponent";
import Register from "../component/verify/Register";
import Maintenance from "../pages/error/MaintenceError";
import InfluencerloginComponent from "../component/verify/InfluencerloginComponent";
import InfluencerForgotComponent from "../component/verify/InfluencerForgotComponent";
import EditOrder from "../component/editforms/EditOrder";

//Common
// const PageNotFound = React.lazy(() => import("../error/PageNotFound"));
// const ForbiddenAccess = React.lazy(() => import("../error/ForbiddenAccess"));

// login-SignUp
// const Login = React.lazy(() => import("../pages/user/login"));
// const NewUserForm = React.lazy(() => import("../pages/user/NewUserForm"));
// const RecoverAccount = React.lazy(() => import("../pages/user/RecoverAccount"));

// public-Pages

// private pages
// const Opportunities = React.lazy(() => import("../pages/Opportunities/index"));
const configureRoutes = () => {
  const RoleBasedComponent = () => {
    const userRole = localStorage.getItem("userRole");
    if (userRole === "Brand") {
      return <DashboardComponent />;
    } else {
      return <MarqDashboardComponent />;
    }
  };
  const routes = [
    // {
    //   element: <LoginForm />,
    //   exact: true,
    //   path: "/login",
    //   title: "Home page",
    //   type: "public",
    // },
    {
      element: <RoleBasedComponent />,
      exact: true,
      path: "/",
      title: "Home page",
      type: "private",
    },

    // {
    //   element: <AddCollab />,
    //   exact: true,
    //   path: "/collab/add",
    //   title: "Add Collab",
    //   type: "private",
    // },
    // {
    //   element: <EditCollab />,
    //   exact: true,
    //   path: "/collab/edit",
    //   title: "Edit Collab",
    //   type: "private",
    // },

    // {
    //   element: <CollabComponent />,
    //   exact: true,
    //   path: "/collab",
    //   title: "Collab Component",
    //   type: "private",
    // },

    {
      element: <CampaignStatsView />,
      exact: true,
      path: "/campaign/stats/:id",
      title: "Campaign View",
      type: "private",
      role: "Brand",
    },

    // new routes for brand
    {
      element: <DashboardComponent />,
      exact: true,
      path: "/brand/",
      title: "Home page",
      type: "private",
      role: "Brand",
    },
    {
      element: <CampaignComponent />,
      exact: true,
      path: "/brand/campaign",
      title: "Campaign",
      type: "private",
      role: "Brand",
    },
    {
      element: <OrderComponent />,
      exact: true,
      path: "/brand/order",
      title: "Order",
      type: "private",
      role: "Brand",
    },
    // marqinfluncer routes

    {
      element: <MarqDashboardComponent />,
      exact: true,
      path: "/influencer/",
      title: "Home page",
      type: "private",
      role: "Influencer",
    },
    {
      element: <AddOrder />,
      exact: true,
      path: "/influencer/order/add",
      title: "Add Order",
      type: "private",
      role: "Influencer",
    },
    {
      element: <EditOrder />,
      exact: true,
      path: "/influencer/order/edit",
      title: "Edit Order",
      type: "private",
      role: "Influencer",
    },
    {
      element: <MarqOrderComponent />,
      exact: true,
      path: "/influencer/order",
      title: "Order",
      type: "private",
      role: "Influencer",
    },
    {
      element: <AddReviewMarq />,
      exact: true,
      path: "/influencer/review/add",
      title: "Add review",
      type: "private",
      role: "Influencer",
    },
    {
      element: <Unauthorized />,
      exact: true,
      path: "/unauthorized",
      title: "Unauthorized",
      type: "public",
    },
    {
      element: <Verify />,
      exact: true,
      path: "/brand/login",
      title: "Login",
      type: "public",
    },
    {
      element: <InfluencerloginComponent />,
      exact: true,
      path: "/influencer/login",
      title: "Influencer Login",
      type: "public",
    },
    {
      element: <Register />,
      exact: true,
      path: "/influencer/register",
      title: "Register",
      type: "public",
    },
    {
      element: <ForgotComponent />,
      exact: true,
      path: "/forgot",
      title: "Forgot",
      type: "public",
    },
    {
      element: <InfluencerForgotComponent />,
      exact: true,
      path: "/influencer/forgot",
      title: "Forgot",
      type: "public",
    },
    {
      element: <Maintenance />,
      exact: true,
      path: "/maintenance",
      title: "Maintenance",
      type: "public",
    },
    {
      element: <PageNotFound />,
      exact: true,
      path: "*",
      title: "404 Not Found",
      type: "error",
    },

    // {
    //   element: <Unauthorized />,
    //   exact: true,
    //   path: "/unauthorized",
    //   title: "Add review",
    //   type: "private",
    //   role: "Admin"

    // },

    // {
    //   element: <OrderComponent />,
    //   exact: true,
    //   path: "/marqinfluncer/order",
    //   title: "Add review",
    //   type: "private",
    // },
  ];

  return routes;
};

export default configureRoutes;

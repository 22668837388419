import React, { useState } from "react";
import {
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import { CustomRequiredLabel } from "../../common/styledCcomponents/styledInput";
import { toast } from "react-toastify";
import { useUser } from "../../contexts/UserDataStore";
import copy from 'clipboard-copy';

const ReferralCard = ({ open, handleClose }) => {
  const { user } = useUser();
  const [referralCode] = useState(user?.referralCode);
  // const copyToClipboard = () => {
  //   navigator.clipboard.writeText(referralCode);
  //   toast.success("Referral code copied to clipboard!");
  // };
  const copyToClipboard = async () => {
    try {
      await copy(referralCode);
      toast.success('Referral code copied!');
    } catch (error) {
      toast.success(`Copy failed! ${error}`);
    }
  };
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "90%", md: "400px" },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            background: "#FFFFFF",
            padding: "50px",
            borderRadius: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <CloseIcon
              onClick={handleClose}
              sx={{
                color: "red",
                width: "30px",
                height: "30px",
                cursor: "pointer",
                marginBottom: "10px",
              }}
            />
          </div>
          <h2 className="admin_note_heading">Refer and Earn</h2>
          <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
          <Grid
            container
            spacing={2}
            sx={{ marginBottom: "20px", marginTop: "20px" }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                paddingBottom: "15px",
                alignItems: "center",
                display: "flex",
              }}
            >
              <FormControl sx={{ width: "100%" }}>
                <div className="flex items-center bg-white rounded shadow-sm">
                  <div>
                    <CustomRequiredLabel>My Referral Code</CustomRequiredLabel>

                    <div className="flex flex-col">
                      <div className="flex flex-row rounded-lg bg-white">
                        <input
                          type="text"
                          class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-l-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 disabled:bg-slate-200 disabled:cursor-not-allowed"
                          required
                          readOnly
                          value={referralCode}
                        />
                        <div class=" flex justify-center items-center w-20 border border-gray-300 border-l-0 rounded-r-lg rounded-l-none   bg-white hover:bg-primary-700 ">
                          <InputAdornment>
                            <IconButton
                              sx={{ color: "#163e6e" }}
                              aria-label="toggle password visibility"
                              onClick={copyToClipboard}
                            >
                              <ContentCopyIcon />
                            </IconButton>
                          </InputAdornment>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </FormControl>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} sm={12}>
            <SmallButton
              onClick={handleSubmit}
              label={isLoading ? "Loading..." : "Submit"}
              width="100%"
              disabled={isLoading}
            />
          </Grid> */}
        </Box>
      </Modal>
    </>
  );
};

export default ReferralCard;

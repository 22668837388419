import React, { useState, useEffect } from "react";
import { PATTERNS } from "../../component/validetion";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  CircularProgress,
  Modal,
  Box,
} from "@mui/material";
import {
  CustomRequiredLabel,
  CustomSubheadingh,
} from "../../common/styledCcomponents/styledInput";
import { DescriptionInput } from "../../common/styledCcomponents/StyledMenu";
import SubmitButton from "../../component/coustombutton/SubmitButton";
import { useGetInfluencerAllOrdersForReview } from "../../component/customHooks/customHook";
import {
  patchFormNewData,
  postFormData,
} from "../../Services/AxoisInterceptorr";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ActiveLastBreadcrumb from "../../component/breadcrumb/ActiveLastBreadcrumb";
import SingleSelect from "../../common/select/SingleSelect";
import CloseIcon from "@mui/icons-material/Close";

const AddReviewMarq = () => {
  const [formData, setFormData] = useState({
    camapignname: {
      value: "",
      isValid: true,
    },
    influncername: {
      value: "",
      isValid: true,
    },
  });

  const [reviewScreenshotFile, setReviewScreenshotFile] = useState(null);
  const [isDisabledNew, setIsDisabledNew] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [image, setImage] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedDeliveredImages, setSelectedDeliveredImages] = useState([]);
  const [currentImage, setCurrentImage] = useState("");
  const [openZoomModal, setOpenZoomModal] = useState(false);

  const { allstatus: OrderDetails, loading } =
    useGetInfluencerAllOrdersForReview();

            const currentDate = new Date();
            const filteredOrders = OrderDetails.filter((item) => {
              const deliveryDate = new Date(item.deliveryDate);
              const tenDaysBeforeCurrentDate = new Date(currentDate);
              tenDaysBeforeCurrentDate.setDate(currentDate.getDate() - 10);
              // Return true tabhi hoga jab delivery date last 10 din ke andar ho
              return deliveryDate >= tenDaysBeforeCurrentDate;
            });
         
  const handleOrderId = (selectedOption) => {
    if (selectedOption) {
      const selectedOrder = OrderDetails.find(
        (order) => order._id === selectedOption.value
      );
      setSelectedOrder(selectedOrder);
    } else {
      setSelectedOrder("");
    }
  };

  // const handleReviewScreenShot = (e) => {
  //   setReviewScreenshotFile(e.target.files[0]);
  //   setImage(URL.createObjectURL(e.target.files[0]));
  // };
  //     const handleReviewScreenShot = (e) => {
  //     setSelectedImages([...selectedImages, ...e.target.files]);
  // };
  const handleReviewScreenShot = (e) => {
    setSelectedImages([
      ...selectedImages,
      ...Array.from(e.target.files).map((file) => ({
        file,
        url: URL.createObjectURL(file),
      })),
    ]);
    e.target.value = null;
  };

  const removeImage = (indexToRemove) => {
    setSelectedImages(
      selectedImages.filter((_, index) => index !== indexToRemove)
    );
  };
  const handledeliveredScreenShot = (e) => {
    setSelectedDeliveredImages([
      ...selectedDeliveredImages,
      ...Array.from(e.target.files).map((file) => ({
        file,
        url: URL.createObjectURL(file),
      })),
    ]);
    e.target.value = null;
  };

  const removeDeliveredImage = (indexToRemove) => {
    setSelectedDeliveredImages(
      selectedDeliveredImages.filter((_, index) => index !== indexToRemove)
    );
  };
  const navigate = useNavigate();

  const handleReview = (e) => {
    e.preventDefault();
    setIsDisabledNew(true);
    setFormSubmitted(true);

    const isFormValid = selectedOrder && selectedImages?.length > 0 && selectedDeliveredImages?.length > 0;
    if (isFormValid) {
      const data = {};
      const newData = new FormData();
      Object.keys(data).forEach((key) => {
        newData.append(key, data[key]);
      });
      if (selectedImages) {
        selectedImages.forEach((file, index) => {
          newData.append("reviewScreenshot", file.file);
        });
      }
      if (selectedDeliveredImages) {
        selectedDeliveredImages.forEach((file, index) => {
          newData.append("deliveredScreenshot", file.file);
        });
      }
      let endpoint = `${VisitorEndpoint.ADD_ORDER__REVIEW}/${selectedOrder?._id}`;

      patchFormNewData(
        endpoint,
        newData,
        () => {},
        (res) => {
          if (res) {
            if (res && res.status === 200) {
              toast.success(res.data.message);
              navigate("/influencer/order");
            }
          }
          setIsDisabledNew(false);
        },
        (error) => {
          toast.error(error.response.data.message);
          setIsDisabledNew(false);
        }
      );
    } else {
      console.log("Form data is not valid.");
      setIsDisabledNew(false);
    }
  };
  // const removeImage = () => {
  //   setImage(null);
  // };
  const handleOpenZoomModal = (imageUrl) => {
    setCurrentImage(imageUrl);
    setOpenZoomModal(true);
  };
  const handleCloseZoomModal = () => {
    setOpenZoomModal(false);
  };
  return (
    <>
    <Modal
    open={openZoomModal}
    onClose={handleCloseZoomModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "auto",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 2,
      }}
    >
      <img
        src={currentImage}
        alt="Zoomed"
        style={{ maxWidth: "100%", maxHeight: "80vh" }}
      />
    </Box>
  </Modal>
    <div className="container_fluid_new">
      <div className="container_new">
        {/* {loading ? (
          <CircularProgress />
        ) : (
          <> */}
        <div>
          {" "}
          <ActiveLastBreadcrumb
            links={[
              { name: "Dashboard", href: "/influencer/" },
              { name: "Order", href: "/influencer/order" },
              { name: "Add Review", href: "/influencer/review/add" },
            ]}
          />
        </div>
        <h1 className="have_solution_heading">Add Review</h1>

        <form onSubmit={handleReview}>
          <Card sx={{ padding: "20px" }}>
            <CardContent>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>Order Id</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Select the Id for the order.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <SingleSelect
                      options={filteredOrders?.map((item) => ({
                        label: item.orderId,
                        value: item._id,
                      }))}
                      value={selectedOrder}
                      onChange={handleOrderId}
                      name="flavor"
                    />
                    {formSubmitted && !selectedOrder && (
                      <p className="input_text_error">
                        *Please select an Order Id.
                      </p>
                    )}
                    {!loading && filteredOrders && filteredOrders.length <= 0 && (
                      <p className="input_text_error">
                        *No orders pending for review.
                      </p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              {/* 
              {selectedOrder && (
                <> */}
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>Brand Name</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Select the brand for the order.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      name="reviewscreenshot"
                      type="text"
                      placeholder="Brand Name"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      disabled
                      value={selectedOrder && selectedOrder.brand.brandName}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>Influencer Name</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Select the Influencer for the order.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      name="reviewscreenshot"
                      type="text"
                      placeholder="Influencer Name
"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      disabled
                      value={
                        selectedOrder && selectedOrder.influencer.influencerName
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>Product Name</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Select the product for the order.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      name="reviewscreenshot"
                      type="text"
                      placeholder="Product Name"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      disabled
                      value={selectedOrder && selectedOrder.product.productName}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>Order Platform</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Select the platform for the order.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      name="reviewscreenshot"
                      type="text"
                      placeholder="Order Platform"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      disabled
                      value={
                        selectedOrder && selectedOrder.orderPlatform.platform
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              {/* </>
     )} */}

              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Review Screenshot
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Upload the screenshot of the successful review of the
                    product.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    {/* <DescriptionInput
                      type="file"
                      size="small"
                      onChange={handleReviewScreenShot}
                      inputProps={{ accept: "image/*" }}
                    /> */}
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleReviewScreenShot}
                      multiple
                      className="bg-gray-10 border border-gray-300 text-gray-900 sm:text-sm rounded  block w-full p-2"
                      style={{color: "transparent"}}
                    />
                    {formSubmitted && !selectedImages.length > 0 && (
                        <p className="input_text_error">
                          *Please upload the review screenshot.
                        </p>
                      )}
                    <div>
                      {/* <input type="file" onChange={handleReviewScreenShot} multiple />
            <div>
                {selectedImages.map((image, index) => (
                    <img 
                        key={index}
                        src={URL.createObjectURL(image)}
                        alt={`Selected ${index}`}
                        style={{ height: '100px', width: '100px' }}
                    />
                ))}
            </div> */}
                    </div>
                    {selectedImages.map((image, index) => (
                      <div
                        key={index}
                        className="mt-5 flex justify-between items-center border-gray-300 border-[1px] rounded-lg"
                      >
                        <div className="flex justify-between p-1">
                          <img
                            src={image.url}
                            alt={`Selected ${index}`}
                            className="w-8 h-8 object-cover  border border-gray-300 self-center"
                            onClick={() =>
                            handleOpenZoomModal(image.url)
                          }
                          />
                          <p className="ml-2">{image.file.name}</p>
                        </div>
                        <CloseIcon
                          onClick={() => removeImage(index)}
                          className="text-red-500 cursor-pointer ml-2"
                        />
                      </div>
                    ))}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                  Delivered & Product Screenshot
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
Upload the delivery screenshot or product screenshot.
 
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    {/* <DescriptionInput
                      type="file"
                      size="small"
                      onChange={handleReviewScreenShot}
                      inputProps={{ accept: "image/*" }}
                    /> */}
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handledeliveredScreenShot}
                      multiple
                      className="bg-gray-10 border border-gray-300 text-gray-900 sm:text-sm rounded  block w-full p-2"
                      style={{color: "transparent"}}
                    />
                    {formSubmitted && !selectedDeliveredImages.length > 0 && (
                        <p className="input_text_error">
                          *Please upload the delivery screenshot or product screenshot.
                        </p>
                      )}
                    <div>
                      {/* <input type="file" onChange={handleReviewScreenShot} multiple />
            <div>
                {selectedImages.map((image, index) => (
                    <img 
                        key={index}
                        src={URL.createObjectURL(image)}
                        alt={`Selected ${index}`}
                        style={{ height: '100px', width: '100px' }}
                    />
                ))}
            </div> */}
                    </div>
                    {selectedDeliveredImages.map((image, index) => (
                      <div
                        key={index}
                        className="mt-5 flex justify-between items-center border-gray-300 border-[1px] rounded-lg"
                      >
                        <div className="flex justify-between p-1">
                          <img
                            src={image.url}
                            alt={`Selected ${index}`}
                            className="w-8 h-8 object-cover  border border-gray-300 self-center"
                            onClick={() =>
                            handleOpenZoomModal(image.url)
                          }
                          />
                          <p className="ml-2">{image.file.name}</p>
                        </div>
                        <CloseIcon
                          onClick={() => removeDeliveredImage(index)}
                          className="text-red-500 cursor-pointer ml-2"
                        />
                      </div>
                    ))}
                  </FormControl>
                </Grid>
              </Grid>

              <SubmitButton
                type={isDisabledNew ? "button" : "submit"}
                label={isDisabledNew ? "Loading..." : "Submit"}
                disabled={isDisabledNew}
              />
            </CardContent>
          </Card>
        </form>
      </div>
    </div>
    </>
  );
};
export default AddReviewMarq;

import React, { useEffect, useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TableSortLabel,
  Chip,
  Avatar,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import { TablePagination } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import NoDataView from "../../common/nodataview/noDataview";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import HistoryIcon from "@mui/icons-material/History";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import RateReviewIcon from "@mui/icons-material/RateReview";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import DynamicBootstrapTooltip from "../tooltip/Tooltip";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import CryptoJS from "crypto-js";

const ShortingTable = ({
  columns,
  data,
  actions,
  loading,
  disabled,
  rowFunction,
  onClickLink,
  onClickTooltip,
  selectedDataProp,
  movertShowIcon,
  showAction,
}) => {
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortField, setSortField] = useState("sno");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [decryptedBrandId, setDecryptedBrandId] = useState(null);
  const open = Boolean(anchorEl);
  const sortedData = useMemo(() => {
    const sorted = [...data].sort((a, b) => {
      if (sortDirection === "asc") {
        return a[sortField] > b[sortField] ? 1 : -1;
      } else {
        return a[sortField] < b[sortField] ? 1 : -1;
      }
    });

    return sorted;
  }, [data, sortField, sortDirection]);

  const [decryptedRole, setDecryptedRole] = useState(null);
  useEffect(() => {
    const encryptedRole = localStorage.getItem("userRole");
    if (encryptedRole) {
      const bytes = CryptoJS.AES.decrypt(
        encryptedRole,
        process.env.REACT_APP_SECRET_KEY
      );
      const decryptedRole = bytes.toString(CryptoJS.enc.Utf8);
      setDecryptedRole(decryptedRole);
    }
  }, []);
  useEffect(() => {
    const encryptedBrandId = localStorage.getItem("userid");
    if (encryptedBrandId) {
      const bytes = CryptoJS.AES.decrypt(
        encryptedBrandId,
        process.env.REACT_APP_SECRET_KEY
      );
      const decryptedId = bytes.toString(CryptoJS.enc.Utf8);
      setDecryptedBrandId(decryptedId);
    }
  }, []);
  const handleRequestSort = (field) => {
    const isAsc = sortField === field && sortDirection === "asc";
    setSortField(field);
    setSortDirection(isAsc ? "desc" : "asc");
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
    rowFunction && rowFunction(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TableContainer component={Paper} style={{ borderRadius: 20 }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{
                    backgroundColor: "#e5e5e5",
                    fontFamily: '"Outfit", sans-serif',
                    color: "#163e6e",
                    fontSize: "16px",
                    fontWeight: 700,
                    textAlign: "center",
                    minWidth: column.minWidth,
                  }}
                  onClick={() => handleRequestSort(column.id)}
                >
                  <TableSortLabel
                    active={sortField === column.id}
                    direction={sortField === column.id ? sortDirection : "asc"}
                    style={{
                      color: "#163e6e",
                    }}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              {actions && (
                <TableCell
                  style={{
                    backgroundColor: "#e5e5e5",
                    fontFamily: '"Outfit", sans-serif',
                    color: "#163e6e",
                    fontSize: "16px",
                    fontWeight: 700,
                    minWidth: 200,
                    textAlign: "center",
                  }}
                >
                  Actions
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              // Display loader when data is loading
              <TableRow>
                <TableCell
                  colSpan={columns.length + (actions ? 1 : 0)}
                  style={{ textAlign: "center" }}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : sortedData.length > 0 ? (
              sortedData.map((row, index) => (
                <TableRow key={row.id}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    let cellStyle = {};

                    if (column.id === "status") {
                      switch (value) {
                        case "Completed":
                          cellStyle = {
                            backgroundColor: "#008000",
                            color: "white",
                            fontFamily: '"Outfit", sans-serif',
                            fontSize: "14px",
                            fontWeight: 500,
                          };
                          break;
                        case "Pending":
                          cellStyle = {
                            backgroundColor: "#FFA500",
                            color: "black",
                            fontFamily: '"Outfit", sans-serif',
                            fontSize: "14px",
                            fontWeight: 500,
                          };
                          break;
                        case "Rejected":
                          cellStyle = {
                            backgroundColor: "red",
                            color: "white",
                            fontFamily: '"Outfit", sans-serif',
                            fontSize: "14px",
                            fontWeight: 500,
                          };
                          break;
                        default:
                          break;
                      }
                    }

                    return (
                      <TableCell
                        key={column.id}
                        style={{
                          fontFamily: '"Outfit", sans-serif',
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "#000000",
                          textAlign: "center",
                        }}
                      >
                        {/* {column.id === 'status' ? <Chip label={value} style={cellStyle} /> : value} */}
                        {column.id === "status" ? (
                          <Chip label={value} style={cellStyle} />
                        ) : column.id === "prdoduct" ? (
                          <Tooltip
                            title={value}
                            placement="top-start"
                            arrow
                            onClick={(e) => {
                              e.preventDefault();
                              onClickTooltip && onClickTooltip(row);
                            }}
                          >
                            {value}
                          </Tooltip>
                        ) : column.id.includes("Image") ||
                          column.id.includes("image") ? (
                          <Avatar src={value} />
                        ) : column.id === "orderid" ||
                          column.id === "campaignname" ||
                          column.id === "campaignnamebrandstats" ? (
                          <a
                            href="#"
                            style={{
                              color: "#163e6e",
                              textDecoration: "underline",
                              cursor: "pointer",
                              fontFamily: '"Outfit", sans-serif',
                              fontWeight: 600,
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              onClickLink && onClickLink(row);
                            }}
                          >
                            {value}
                          </a>
                        ) : (
                          value
                        )}
                      </TableCell>
                    );
                  })}
                  {actions && (
                    <TableCell style={{ textAlign: "center" }}>
                      {actions.map(
                        (action, index) =>
                          action.icon === NoteAddIcon && (
                            <DynamicBootstrapTooltip
                              key={index}
                              tooltipContent="Update Status"
                            >
                              <IconButton
                                onClick={() => action.onClick(row)}
                                style={{
                                  color: "#A020F0",
                                  backgroundColor: "#ecd4fa",
                                  borderRadius: "50%",
                                  marginRight: "10px",
                                }}
                              >
                                <action.icon />
                              </IconButton>
                            </DynamicBootstrapTooltip>
                          )
                      )}

                      {decryptedBrandId === "664b086803e130aa2136f77e" &&
                        actions.map(
                          (action, index) =>
                            action.icon === HistoryIcon && (
                              <DynamicBootstrapTooltip
                                key={index}
                                tooltipContent="Note"
                              >
                                <IconButton
                                  onClick={() => action.onClick(row)}
                                  style={{
                                    color: "#1DB5E7",
                                    backgroundColor: "#ceeffa",
                                    borderRadius: "50%",
                                    marginRight: "10px",
                                  }}
                                >
                                  <action.icon />
                                </IconButton>
                              </DynamicBootstrapTooltip>
                            )
                        )}
                      {decryptedRole === "Influencer" &&
                        actions.map(
                          (action, index) =>
                            action.icon === HistoryIcon && (
                              <DynamicBootstrapTooltip
                                key={index}
                                tooltipContent="Note"
                              >
                                <IconButton
                                  onClick={() => action.onClick(row)}
                                  style={{
                                    color: "#1DB5E7",
                                    backgroundColor: "#ceeffa",
                                    borderRadius: "50%",
                                    marginRight: "10px",
                                  }}
                                >
                                  <action.icon />
                                </IconButton>
                              </DynamicBootstrapTooltip>
                            )
                        )}
                      {decryptedRole === "Influencer" &&
                        actions.map(
                          (action, index) =>
                            action.icon === EditIcon && (
                              <span
                                style={{
                                  cursor:
                                    row?.status === "Placed" &&
                                    row?.orderManager === null
                                      ? "default"
                                      : "not-allowed",
                                }}
                              >
                                <DynamicBootstrapTooltip
                                  key={index}
                                  tooltipContent="Edit Order"
                                >
                                  <IconButton
                                    onClick={() => {
                                      action.onClick(row);
                                    }}
                                    style={{
                                      color: "#163e6e",
                                      backgroundColor: "#E7EBF0",
                                      borderRadius: "50%",
                                      marginRight: "10px",
                                      opacity:
                                        row?.status === "Placed" &&
                                        row?.orderManager === null
                                          ? 1
                                          : 0.4,
                                    }}
                                    disabled={
                                      row?.status === "Placed" &&
                                      row?.orderManager === null
                                        ? false
                                        : true
                                    }
                                  >
                                    <action.icon />
                                  </IconButton>
                                </DynamicBootstrapTooltip>
                              </span>
                            )
                        )}

                      {decryptedRole === "Influencer" &&
                        actions.map(
                          (action, index) =>
                            action.icon === RateReviewIcon && (
                              <span
                                style={{
                                  cursor:
                                    row?.status !== "Reviewed"
                                      ? "not-allowed"
                                      : "default",
                                }}
                              >
                                <DynamicBootstrapTooltip
                                  key={index}
                                  tooltipContent="Edit Review"
                                >
                                  <IconButton
                                    onClick={() => {
                                      action.onClick(row);
                                    }}
                                    style={{
                                      color: "#A020F0",
                                      backgroundColor: "#ecd4fa",
                                      borderRadius: "50%",
                                      marginRight: "10px",
                                      opacity:
                                        row?.status !== "Reviewed" ? 0.4 : 1,
                                    }}
                                    disabled={row?.status !== "Reviewed"}
                                  >
                                    <action.icon />
                                  </IconButton>
                                </DynamicBootstrapTooltip>
                              </span>
                            )
                        )}
                      {/* {actions.map(
                        (action, index) =>
                          action.icon === HistoryIcon && (
                            <Tooltip
                              key={index}
                              placement="top"
                              title="History"
                              arrow
                            >
                              <IconButton
                                onClick={() => action.onClick(row)}
                                style={{
                                  color: "#163e6e",
                                  backgroundColor: "#e7f3ff",
                                  borderRadius: "50%",
                                  marginRight: "10px",
                                }}
                              >
                                <action.icon />
                              </IconButton>
                            </Tooltip>
                          )
                      )} */}
                      {actions.map(
                        (action, index) =>
                          action.icon === VisibilityIcon && (
                            <DynamicBootstrapTooltip
                              key={index}
                              tooltipContent="View Details"
                            >
                              <IconButton
                                onClick={() => action.onClick(row)}
                                style={{
                                  color: "green",
                                  backgroundColor: "#bae0ba",
                                  borderRadius: "50%",
                                  marginRight: "10px",
                                }}
                              >
                                <action.icon />
                              </IconButton>
                            </DynamicBootstrapTooltip>
                          )
                      )}
                      {actions.map(
                        (action, index) =>
                          action.icon === ShoppingBagIcon && (
                            <DynamicBootstrapTooltip
                              key={index}
                              tooltipContent="View Orders"
                            >
                              <IconButton
                                onClick={() => action.onClick(row)}
                                style={{
                                  color: "purple",
                                  backgroundColor: "#edb8ed",
                                  borderRadius: "50%",
                                  marginRight: "10px",
                                }}
                              >
                                <action.icon />
                              </IconButton>
                            </DynamicBootstrapTooltip>
                          )
                      )}
                      {movertShowIcon && (
                        <>
                          <Tooltip placement="top" title="More">
                            <IconButton
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-haspopup="true"
                              onClick={(event) => handleClick(event, row)}
                              style={{
                                backgroundColor: "#d2e5fc",
                                borderRadius: "50%",
                                color: "#163e6e",
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </Tooltip>
                          <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                              style: {
                                boxShadow:
                                  "0px 0px 1px 1px rgba(22, 62, 110, 0.5)",
                              },
                            }}
                          >
                            {actions.map((action, index) => {
                              if (action.icon !== NoteAddIcon) {
                                let color;
                                let label = "";
                                if (action.icon === VisibilityIcon) {
                                  color = "green";
                                  label = "Details";
                                } else if (
                                  action.icon === SignalCellularAltIcon
                                ) {
                                  color = "#000";
                                  label = "Stats";
                                } else if (action.icon === EditIcon) {
                                  color = "#163e6e";
                                  label = "Edit";
                                } else if (action.icon === DeleteIcon) {
                                  color = "red";
                                  label = "Delete";
                                } else if (action.icon === HistoryIcon) {
                                  color = "#1DB5E7";
                                  label = "Note History";
                                } else if (action.icon === ReceiptLongIcon) {
                                  color = "#5D3FD3";
                                  label = "Pay Reimbursement";
                                } else if (action.icon === LocalShippingIcon) {
                                  color = "#5D3FE3";
                                  label = "deliverables";
                                } else if (action.icon === RateReviewIcon) {
                                  color = "#0e2f44";
                                  label = "Add Review";
                                }

                                return (
                                  <MenuItem
                                    key={index}
                                    onClick={() => {
                                      action.onClick(currentRow);
                                      handleClose();
                                    }}
                                    disabled={action.disabled}
                                  >
                                    <ListItemIcon
                                      style={{
                                        color: color,
                                        opacity: action.disabled ? 0.5 : 1,
                                      }}
                                    >
                                      <action.icon />
                                    </ListItemIcon>
                                    <ListItemText
                                      primaryTypographyProps={{
                                        style: {
                                          fontFamily: '"Outfit", sans-serif',
                                          fontWeight: 600,
                                          fontSize: "14px",
                                        },
                                      }}
                                      primary={label}
                                    />
                                  </MenuItem>
                                );
                              }
                            })}
                          </Menu>
                        </>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length + 1}>
                  <NoDataView loading={loading} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </>
  );
};

export default ShortingTable;
// .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

import React from "react";

const ImageIcon = ({ src, alt, width = 22, height = 22, marginRight = 2 }) => {
  return (
    <img
      src={src}
      alt={alt}
      style={{ width: width, height: height, marginRight: marginRight }}
    />
  );
};

export default ImageIcon;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Badge,
  Button,
  Checkbox,
  FormControlLabel,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { SmallButton } from "../../component/coustombutton/SmallButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { DescriptionInput } from "../styledCcomponents/StyledMenu";
import ClearIcon from "@mui/icons-material/Clear";
const FilterPopupComponent = ({ filters, onChange, filterKey }) => {
  const filterParam = useSelector((state) => state.filter[filterKey]);

  const parseFilterParams = (filterString) => {
    if (!filterString) return {};
    return filterString.split("&").reduce((acc, cur) => {
      const [key, values] = cur.split("=");
      acc[key] = values ? values.split(",") : [];
      return acc;
    }, {});
  };

  const [selectedValues, setSelectedValues] = useState(
    parseFilterParams(filterParam)
  );
  const [searchQueries, setSearchQueries] = useState(
    filters.reduce((acc, filter) => {
      acc[filter.urlParam] = "";
      return acc;
    }, {})
  );

  const [menuStates, setMenuStates] = useState(
    filters.reduce((acc, filter) => {
      acc[filter.urlParam] = { anchorEl: null, isOpen: false };
      return acc;
    }, {})
  );

  useEffect(() => {
    setSelectedValues(parseFilterParams(filterParam));
  }, [filterParam]);

  // useEffect(() => {
  //   const queryString = Object.entries(selectedValues)
  //     .map(([key, values]) => `${key}=${values.join(",")}`)
  //     .join("&");
  //   onChange(queryString);
  // }, [selectedValues, onChange]);
  const updateFilter = () => {
    const queryString = Object.entries(selectedValues)
      .map(([key, values]) => `${key}=${values.join(",")}`)
      .join("&");
    onChange(queryString, filterKey);
  };

  useEffect(updateFilter, [selectedValues]);

  const handleClick = (event, filterParam) => {
    setMenuStates({
      ...menuStates,
      [filterParam]: {
        ...menuStates[filterParam],
        anchorEl: event.currentTarget,
        isOpen: true,
      },
    });
  };

  const handleClose = (filterParam) => {
    setMenuStates({
      ...menuStates,
      [filterParam]: {
        ...menuStates[filterParam],
        anchorEl: null,
        isOpen: false,
      },
    });
  };

  const handleChange = (event, value, filterParam) => {
    const newValues = selectedValues[filterParam]?.includes(value)
      ? selectedValues[filterParam].filter((item) => item !== value)
      : [...(selectedValues[filterParam] || []), value];
    setSelectedValues((prev) => ({
      ...prev,
      [filterParam]: newValues,
    }));
    setTimeout(updateFilter, 0);
  };

  const handleSearchChange = (event, filterParam) => {
    const value = event.target.value;
    setSearchQueries({ ...searchQueries, [filterParam]: value });
  };

  const handleClearAllFilters = () => {
    setSelectedValues({});
    setSearchQueries(
      filters.reduce((acc, filter) => {
        acc[filter.urlParam] = "";
        return acc;
      }, {})
    );
    setMenuStates(
      filters.reduce((acc, filter) => {
        acc[filter.urlParam] = { anchorEl: null, isOpen: false };
        return acc;
      }, {})
    );
    onChange("", filterKey);
  };
  const handleClear = (filterParam) => {
    // Reset selected values for this filter category
    setSelectedValues((prev) => ({ ...prev, [filterParam]: [] }));

    // Reset search query for this filter category
    setSearchQueries((prev) => ({ ...prev, [filterParam]: "" }));

    setMenuStates((prev) => ({
      ...prev,
      [filterParam]: { ...prev[filterParam], isOpen: false },
    }));
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
        {filters.map((filterGroup) => (
          <React.Fragment key={filterGroup.urlParam}>
            <Badge
              badgeContent={selectedValues[filterGroup.urlParam]?.length || 0}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              sx={{
                "& .MuiBadge-badge": {
                  zIndex: "0",
                  color: "#FFF",
                  backgroundColor: "red",
                  fontSize: "12px",
                },
              }}
            >
              <SmallButton
                background="#fff"
                color="#163e6e"
                onClick={(event) => handleClick(event, filterGroup.urlParam)}
                label={filterGroup.label}
                endIcon={
                  menuStates[filterGroup.urlParam].isOpen ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                }
              />
            </Badge>
            <Menu
              anchorEl={menuStates[filterGroup.urlParam].anchorEl}
              open={menuStates[filterGroup.urlParam].isOpen}
              onClose={() => handleClose(filterGroup.urlParam)}
              PaperProps={{ style: { maxHeight: 300, marginTop: 8 ,zIndex:1} }}
              MenuListProps={{ sx: { py: 0 } }}
              disableScrollLock={true}

            >
              {/* <div style={{ padding: "10px", }}> */}

              <div className="relative p-0">
                <div className="p-3 sticky top-0  border-b-2 bg-white z-20">
                  <DescriptionInput
                    variant="outlined"
                    size="small"
                    fullWidth
                    placeholder={`Search ${filterGroup.label}`}
                    value={searchQueries[filterGroup.urlParam]}
                    onChange={(event) =>
                      handleSearchChange(event, filterGroup.urlParam)
                    }
                    onKeyDown={(event) => event.stopPropagation()}
                    style={{ marginBottom: "10px" }}
                  />
                </div>

                <div>
                  {filterGroup.values
                    .filter((filter) =>
                      filter.label
                        .toLowerCase()
                        .includes(
                          searchQueries[filterGroup.urlParam].toLowerCase()
                        )
                    )
                    .sort((a, b) => {
                      // Move selected item to the top
                      const aSelected = selectedValues[
                        filterGroup.urlParam
                      ]?.includes(a.value);
                      const bSelected = selectedValues[
                        filterGroup.urlParam
                      ]?.includes(b.value);
                      return aSelected ? -1 : bSelected ? 1 : 0;
                    })
                    .map((filter) => (
                      <MenuItem key={filter.value}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedValues[
                                filterGroup.urlParam
                              ]?.includes(filter.value)}
                              onChange={(event) =>
                                handleChange(
                                  event,
                                  filter.value,
                                  filterGroup.urlParam
                                )
                              }
                            />
                          }
                          label={
                            <ListItemText
                              primary={filter.label}
                              sx={{
                                "& .MuiTypography-root": {
                                  fontFamily: '"Outfit", sans-serif',
                                  fontSize: "14px",
                                  fontWeight: 500,
                                  color: "#000000",
                                },
                              }}
                            />
                          }
                        />
                      </MenuItem>
                    ))
                    .concat(
                      filterGroup.values.length === 0 ||
                        filterGroup.values.filter((filter) =>
                          filter.label
                            .toLowerCase()
                            .includes(
                              searchQueries[filterGroup.urlParam].toLowerCase()
                            )
                        ).length === 0 ? (
                        <MenuItem disabled>
                          <ListItemText
                            primary="No data available"
                            sx={{
                              "& .MuiTypography-root": {
                                fontFamily: '"Outfit", sans-serif',
                                fontSize: "14px",
                                fontWeight: 800,
                                color: "#000000",
                              },
                            }}
                          />
                        </MenuItem>
                      ) : null
                    )}
                </div>

                <div className="sticky border-t-2 bottom-0 z-20 bg-white flex flex-row items-end justify-end p-2">
                  {/* <SmallButton
                  onClick={() => handleClear(filterGroup.urlParam)}
                  label={`Clear ${filterGroup.label}`}
                  background="red"
                  marginRight="10px"
                  padding="2px 10px"
                  fontSize="14px"
                /> */}
                  <div
                    onClick={() => handleClear(filterGroup.urlParam)}
                    className="flex flex-row items-center justify-center hover:bg-gray-100 p-2 border-2 rounded-md cursor-pointer"
                    style={{  color: "#163e6e",
                              cursor: "pointer",
                              fontFamily: '"Outfit", sans-serif',
                              fontWeight: 500,}}
                  >
                    <ClearIcon
                      sx={{ width: "20px", height: "20px",color:"#163e6e",marginRight:"2px",fontWeight: 600, }}
                    />
                    Clear
                  </div>
                </div>
              </div>
            </Menu>
          </React.Fragment>
        ))}
        <SmallButton
          label="Reset"
          onClick={handleClearAllFilters}
          background="#fff"
          color="#163e6e"
        />
      </div>
    </div>
  );
};

export default FilterPopupComponent;

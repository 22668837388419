import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  brandFilter: '',
  productFilter: '',
  marqfluencerFilter: '',
  webMarqfluencerFilter: '',
  webBrandFilter: '',
  campaignFilter: '',
  orderFilter: '',
  collabFilter: '',
  paymentFilter: '',
  reimbursedFilter: '',
  
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    resetFilter: () => initialState,
    setBrandFilter: (state, action) => {
      state.brandFilter = action.payload;
    },
    setProductFilter: (state, action) => {
      state.productFilter = action.payload;
    },
    setMarqfluencerFilter: (state, action) => {
      state.marqfluencerFilter = action.payload;
    },
    setWebMarqfluencerFilter: (state, action) => {
      state.webMarqfluencerFilter = action.payload;
    },
    setWebBrandFilter: (state, action) => {
      state.webBrandFilter = action.payload;
    },
    setCampaignFilter: (state, action) => {
      state.campaignFilter = action.payload;
    },
    setOrderFilter: (state, action) => {
      state.orderFilter = action.payload;
    },
    setCollabFilter: (state, action) => {
      state.collabFilter = action.payload;
    },
    setPaymentFilter: (state, action) => {
      state.paymentFilter = action.payload;
    },
    setReimbursedFilter: (state, action) => {
      state.reimbursedFilter = action.payload;
    },
  },
});

export const {
  resetFilter,
  setBrandFilter,
  setProductFilter,
  setMarqfluencerFilter,
  setWebMarqfluencerFilter,
  setWebBrandFilter,
  setCampaignFilter,
  setOrderFilter,
  setCollabFilter,
  setPaymentFilter,
  setReimbursedFilter
} = filterSlice.actions;

export default filterSlice.reducer;

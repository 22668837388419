import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BrandData } from "./SidebarData";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import SubMenu from "./SubMenu";
import "./Sidebar.css"; // Import the CSS file
import { SidebarDataOperations } from "./SidebarDataOperations";
import CryptoJS from "crypto-js";
import { useDispatch } from "react-redux";
import { resetFilter } from "../../store/FilterSlice";
import LogoutIcon from "@mui/icons-material/Logout";
import { postJsonData } from "../../Services/AxoisInterceptorr";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { toast } from "react-toastify";
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import ReferralCard from "../referral/ReferralCard";

const Sidebar = () => {
  const dispatch = useDispatch();
  const [sidebar, setSidebar] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1080);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);
  const [loading, setLoading] = useState(false);
  const [decryptedRole, setDecryptedRole] = useState(null);
  useEffect(() => {
    const encryptedRole = localStorage.getItem("userRole");
    if (encryptedRole) {
      const bytes = CryptoJS.AES.decrypt(
        encryptedRole,
        process.env.REACT_APP_SECRET_KEY
      );
      const decryptedRole = bytes.toString(CryptoJS.enc.Utf8);
      setDecryptedRole(decryptedRole);
    }
  }, []);

  const filteredSidebarData =
    decryptedRole === "Brand" ? BrandData : SidebarDataOperations;
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1080);
      if (window.innerWidth >= 1080) {
        setSidebar(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // const logOut = () => {
    
  //   localStorage.clear();
  //   dispatch(resetFilter())
  //   if (decryptedRole === "Brand") {
  //     return navigate("/brand/login");
  //   } else if (decryptedRole === "Influencer") {
  //     return navigate("/Influencer/login");
  //   }
  // };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const logOut = () => {
    setLoading(true);
    const data = {};
    postJsonData(
      VisitorEndpoint?.LOGOUT,
      data,
      () => {},
      (res) => {
        if (res && res.status === 200) {
          toast.success(res.data.message);

          // Clear local storage
          localStorage.clear();

          // Reset filter
          dispatch(resetFilter());
          if (decryptedRole === "Brand") {
            navigate("/brand/login");
          } else if (decryptedRole === "Influencer") {
            navigate("/influencer/login");
          }
        }
        setLoading(false);
      },
      (error) => {
        toast.error(error.response.data.message);
        setLoading(false);
      }
    );
  };
  const handleLogout = () => {
    logOut();
  };
  return (
    <>
              <ReferralCard open={isModalOpen} handleClose={closeModal} />

      <div className="navbar">
        <div>
          <p className="logo_header">marqeting</p>
        </div>
        <Link to="#" className="nav-icon">
          <MenuIcon
            onClick={showSidebar}
            sx={{ color: "#ffffff", fontSize: 42 }}
          />
        </Link>
      </div>
      <div className={sidebar ? "sidebar-nav sidebar-nav-open" : "sidebar-nav"}>
        <div className="sidebar-wrap" onClick={showSidebar}>
          <Link to="#" className="nav-icon" >
            <CloseIcon
              // onClick={showSidebar}
              sx={{ color: "#163e6e", fontSize: 42 }}
            />
          </Link>
          {filteredSidebarData.map((item, index) => {
            return <SubMenu item={item} key={index} />;
          })}

          {decryptedRole === "Influencer" && (
          <div
            style={{ display: "flex", padding: "20px", alignItems: "center" }}
            onClick={openModal}
          >
            <ConnectWithoutContactIcon sx={{ color: "#163e6e", fontSize: 25 }} />
            <p
              style={{
                marginLeft: "20px",
                fontFamily: '"Outfit", sans-serif',
                fontWeight: 500,
                color: "#163e6e",
                fontSize: "18px",
              }}
            >
            Refer and Earn
              
            </p>
          </div>
        )}
          <div
            style={{ display: "flex", padding: "20px", alignItems: "center" }}
            onClick={() => {
              handleLogout();
            }}
            disabled={loading}
          >
            <LogoutIcon sx={{ color: "#163e6e", fontSize: 25 }} />
            <p
              style={{
                marginLeft: "20px",
                fontFamily: '"Outfit", sans-serif',
                fontWeight: 500,
                color: "#163e6e",
                fontSize: "18px",
              }}
            >
            {loading ? "Logging out" : "Log Out"}
              
            </p>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default Sidebar;

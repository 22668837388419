import React from "react";
import styled from "@emotion/styled";
import { Box, Button, OutlinedInput } from "@mui/material";

const InputLabel = styled.label`
  color: #163e6e;
  font-size: 16px;
  font-family: Outfit;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 8px;
`;
const RequiredLabel = styled.label`
  color: #163e6e;
  font-size: 16px;
  font-family: Outfit;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;
const HeadingLabel = styled.label`
  color: #64748bcc;
  font-size: 14px;
  font-family: Outfit;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 8px;
`;
const HeadingproductLabel = styled.label`
  color: #000000;
  font-size: 14px;
  font-family: Outfit;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 8px;
`;

export const CustomInputLabel = ({ children }) => (
  <InputLabel>{children}</InputLabel>
);
export const CustomSubheadingh = ({ children }) => (
  <HeadingLabel>{children}</HeadingLabel>
);
export const CustomProductSubheadingh = ({ children }) => (
  <HeadingproductLabel>{children}</HeadingproductLabel>
);


export const CustomRequiredLabel = ({ children, required,Optional }) => (
  <Box sx={{ display: 'flex', alignItems: 'center',marginBottom:"10px" }}>
    <RequiredLabel>{children}</RequiredLabel>
    {required && <button className="required_button">Required</button>}
    {Optional && <button className="required_button">Optional</button>}
  </Box>
);
 

export   const CustomOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
    "&.MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid #F2F8FD",
        height: "48px",
        borderRadius: "4px",
      },
      "&:hover fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #DDD",
      },
    },
  }));
import React from "react";
import PaidIcon from "@mui/icons-material/Paid";
import { IconButton, useMediaQuery } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

const CardComponent = ({
  icon: Icon,
  heading,
  data,
  logoColor,
  color,
  image,
  loading,
  width = "300px",
  height = "200px",
}) => {
  const isSmall = useMediaQuery("(max-width:500px)");
  return (
    <div
      className="card_component"
      style={{ width: isSmall ? "90%" : width, height: height }}
    >
      {loading ? (
        <>
          <Skeleton variant="circular" width={50} height={50} />
          <Skeleton variant="text" sx={{ fontSize: "20px" }} />
          <Skeleton variant="text" sx={{ fontSize: "28px" }} />
        </>
      ) : (
        <>
          {Icon && !image && (
            <div className={`logo_container logo_${logoColor}`}>
              <IconButton>
                <Icon
                  sx={{
                    color: "#000",
                    fontSize: 22,
                  }}
                />
              </IconButton>
            </div>
          )}
          {image && (
            <div className={`logo_container logo_${logoColor}`}>
              <img
                src={image}
                alt="Logo"
                style={{
                  color: "#000",
                  width: "22px",
                  height: "22px",
                }}
              />
            </div>
          )}

          <h1 className="card_heading">{heading}</h1>
          <p className={color ? `card_para payment_${color}` : "card_para"}>
            {data || 0}
          </p>
        </>
      )}
    </div>
  );
};

export default CardComponent;

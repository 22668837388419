import { ErrorBoundary } from 'react-error-boundary'
import { SmallButton } from '../component/coustombutton/SmallButton';

function ErrorFallback({ error, resetErrorBoundary }) {
  // const handleGoHome = () => {
  //   navigate("/brand/login");
  // };
  return (
    <div className="min-h-screen flex flex-col justify-center items-center">
      <div className="w-[50%]">
        <div className="flex flex-col items-center">
          <h1 className="font-semibold text-lg">
            500.{" "}
            <span className="font-normal	 text-sm text-gray-700">
              That's an error.
            </span>
          </h1>
        </div>
        <div className="mt-8 flex flex-col items-center">
          <p className="text-2xl mt-8 w-full  sm:w-[70%] text-gray-700 text-center">
            The server encountered an error and could not complete your request
          </p>
          {/* <p className="text-2xl mt-8 w-full  sm:w-[70%] text-gray-700 text-center">
          {error.message}
          </p> */}
        </div>
        {/* <div className="mt-6 flex flex-col items-center">
          <p className="mt-16 text-8xl text-primary">500</p>
        </div> */}
        <div className="mt-4">
          <SmallButton
            fontSize="12px"
            label="Go to Home"
            // onClick={handleGoHome}
            onClick={resetErrorBoundary}
          />
        </div>
      </div>
    </div>
  );
  // return (
  //   <div>
  //     <p>Something went wrong:</p>
  //     <pre>{error.message}</pre>
  //     <button onClick={resetErrorBoundary}>Try again</button>
  //   </div>
  // )
}

export default function MyErrorBoundary(props) {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {   window.location.reload();
        // reset the state of your app here
      }}
    >
      {props.children}
    </ErrorBoundary>
  )
}

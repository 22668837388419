import { Box, Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Unauthorizedd } from '../../component/IconsImport';

const Unauthorized = () => {
  const navigate = useNavigate();
  const userRole = localStorage.getItem("userRole");

  const handleGoHome = () => {
    if (userRole === "Admin") {
      navigate('/brand/');
    } else if (userRole === "Operations") {
      navigate('/influencer/');
    } else {
      navigate('/login');
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <img style={{width:"500px"}} src={Unauthorizedd} alt="Unauthorized" />
      <Button variant="contained" color="primary" onClick={handleGoHome}>
        Go to Home
      </Button>
    </Box>
  );
};

export default Unauthorized;

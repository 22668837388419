import React, { useCallback, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CloseIcon from "@mui/icons-material/Close";
import {
  TextField,
  Grid,
  FormControl,
  Divider,
  CircularProgress,
  Avatar,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import CustomReadOnlyTextField from "../ReadonlyInput";
import {
  CustomInputLabel,
  CustomProductSubheadingh,
  CustomSubheadingh,
} from "../../common/styledCcomponents/styledInput";
import HistoryIcon from "@mui/icons-material/History";
import { SmallButton } from "../coustombutton/SmallButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShortingTable from "../tabel/ShortingTabel";
import { NoDataImage } from "../IconsImport";
import DOMPurify from "dompurify";
export default function CampaignView({
  influencerDrawerOpen,
  setInfluencerDrawerOpen,
  rowData,
}) {
  const productDetailscolumns = [
    { id: "sno", label: "S.No.", minWidth: 200 },

    { id: "productname", label: "Product Name", minWidth: 200 },
    { id: "productquantity", label: "Product Quantity", minWidth: 200 },
  ];
  const renderContent = useCallback((content = "") => {
    const sanitizedBlog = DOMPurify.sanitize(content);
    const renderHTML = () => (
      <div dangerouslySetInnerHTML={{ __html: sanitizedBlog }}></div>
    );
    return renderHTML();
  }, []);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setInfluencerDrawerOpen(open);
  };

  const productData =
    rowData?.product.length > 0 &&
    rowData?.product?.map((item, index) => ({
      sno: index + 1,

      productname: item?.productName && item?.productName,
      productquantity: item.productQuantity && item.productQuantity,
    }));

  const list = (
    <Box
      role="presentation"
      //   onKeyDown={toggleDrawer(false)}
    >
      <List>
        <div className="brands_drawer">
          <div className="brands_drawer_name_section">
            <h1>Details</h1>
            <CloseIcon
              style={{
                width: "30px",
                height: "30px",
                color: "#163e6e",
                cursor: "pointer",
              }}
              onClick={() => setInfluencerDrawerOpen(false)}
            />
          </div>

          {rowData ? (
            <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
              <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                <div>
                  <CustomInputLabel>Campaign Name</CustomInputLabel>
                </div>
                <CustomProductSubheadingh>
                  {rowData?.campaignName}
                </CustomProductSubheadingh>
              </Grid>
              <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />

              <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                <div>
                  <CustomInputLabel>Brand Name</CustomInputLabel>
                </div>
                <CustomProductSubheadingh>
                  {rowData?.brand[0]?.brandName}
                </CustomProductSubheadingh>
              </Grid>
              <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />

              {/* <div key={index}>
    <p>Product Name: {item.productId.productName}</p>
    <p>Product Quantity: {item.productQuantity}</p>
  </div>
))} */}
              {rowData?.product.length > 0 && (
                <>
                  <CustomInputLabel>Product Details</CustomInputLabel>
                  <Divider sx={{ marginY: 1, borderBottom: "none" }} />
                  <ShortingTable
                    columns={productDetailscolumns}
                    // loading={activeCampaignDetailsLoading}
                    data={productData}
                  />
                  <Divider
                    sx={{ marginY: 2, borderBottom: "1px solid #ccc" }}
                  />
                </>
              )}

              <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                <div>
                  <CustomInputLabel>Campaign Platform</CustomInputLabel>
                </div>
                <CustomProductSubheadingh>
                  {rowData?.campaignPlatform
                    .map((categoryObj) => categoryObj.platform)
                    .join(", ")}
                </CustomProductSubheadingh>
              </Grid>

              <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />

              <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                <div>
                  <CustomInputLabel>Campaign Type</CustomInputLabel>
                </div>
                <CustomProductSubheadingh>
                  {rowData?.campaignType &&
                    rowData?.campaignType[0]?.campaignType}
                </CustomProductSubheadingh>
              </Grid>

              <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />

              <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                <div>
                  <CustomInputLabel>Campaign Brief</CustomInputLabel>
                </div>
                <div
                  style={{
                    paddingLeft: "20px",
                    paddingTop: "10px",
                    fontFamily: '"Outfit", sans-serif',
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "#000",
                  }}
                >
                  <p className="text_list"> {renderContent(rowData?.campaignBrief)}</p>
                </div>
              </Grid>

              <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />

              <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                <div>
                  <CustomInputLabel>Campaign Start Date</CustomInputLabel>
                </div>
                <CustomProductSubheadingh>
                  {
                    new Date(rowData?.campaignStartDate)
                      .toISOString()
                      .split("T")[0]
                  }
                </CustomProductSubheadingh>
              </Grid>

              {rowData?.campaignEndDate && (
                <>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Campaign End Date</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {
                        new Date(rowData?.campaignEndDate)
                          .toISOString()
                          .split("T")[0]
                      }
                    </CustomProductSubheadingh>
                  </Grid>
                </>
              )}
              <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
              <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                <div>
                  <CustomInputLabel>No of Content Pieces</CustomInputLabel>
                </div>
                <CustomProductSubheadingh>
                  {rowData?.noOfContent}
                </CustomProductSubheadingh>
              </Grid>

              <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
              <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                <div>
                  <CustomInputLabel>Service Cost Per Content</CustomInputLabel>
                </div>
                <CustomProductSubheadingh>
                  {rowData?.serviceCostPerContent}
                </CustomProductSubheadingh>
              </Grid>
              <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
              <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                <div>
                  <CustomInputLabel>Total Service Cost</CustomInputLabel>
                </div>
                <CustomProductSubheadingh>
                  {rowData?.totalServiceCost}
                </CustomProductSubheadingh>
              </Grid>

              <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
              <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                <div>
                  <CustomInputLabel>Total GST</CustomInputLabel>
                </div>
                <CustomProductSubheadingh>
                  {rowData?.gstCost}
                </CustomProductSubheadingh>
              </Grid>

              <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
              <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                <div>
                  <CustomInputLabel>Total Reimbursement Cost</CustomInputLabel>
                </div>
                <CustomProductSubheadingh>
                  {rowData?.reimbursementCost}
                </CustomProductSubheadingh>
              </Grid>

              <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
              <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                <div>
                  <CustomInputLabel>Total Campaign Cost</CustomInputLabel>
                </div>
                <CustomProductSubheadingh>
                  {rowData?.totalCampaignCost}
                </CustomProductSubheadingh>
              </Grid>
              <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
            </Grid>
          ) : (
            <Grid container justifyContent="center" alignItems="center">
              <CircularProgress />
            </Grid>
          )}
        </div>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key="right">
        <Drawer
          anchor="right"
          open={influencerDrawerOpen}
          onClose={toggleDrawer(false)}
          sx={{
            "& .MuiDrawer-paper": {
              overflow: "auto",
              background: "#f4faff",
              width: "50%",
              "@media (max-width: 600px)": {
                width: "90%",
              },
            },
          }}
        >
          {list}
        </Drawer>
      </React.Fragment>
    </div>
  );
}

import React from "react";
import Marquee from "react-fast-marquee";

const MarqueeText = ({message}) => (
    <Marquee
      gradient={false}
      speed={80} 
      pauseOnClick={true}
      pauseOnHover={true}

      autoFill={true}
    >
    <p style={{color:"red",background:"#fff",padding:"2px 15px",borderRadius:"10px",marginRight:"10px"}}>      {message}</p>

    </Marquee>
  );


export default MarqueeText;
import React, { useState, useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import { TextField, Menu, MenuItem, Badge } from "@mui/material";
import { SmallButton } from "../coustombutton/SmallButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { PaddingOutlined } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { setOrderFilter } from "../../store/FilterSlice";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import dayjs from "dayjs";

const DateRangeFilter = ({ onApply, filterName }) => {
  const dispatch = useDispatch();
  const orderDateNewFilter = useSelector((state) => state.filter.orderFilter);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (orderDateNewFilter) {
      // Find the start and end dates in the orderDateNewFilter string
      const startDateString = orderDateNewFilter.match(
        /orderdate\[\$gte\]=(.*?)&/
      )?.[1];
      const endDateString = orderDateNewFilter.match(
        /orderdate\[\$lte\]=(.*?)&/
      )?.[1];

      // Convert the date strings to Day.js objects
      if (startDateString) {
        setStartDate(dayjs(startDateString));
      }
      if (endDateString) {
        setEndDate(dayjs(endDateString));
      }
    }
  }, [orderDateNewFilter]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleApply = () => {
  //   if (startDate && endDate) {
  //     onApply({
  //       startDate: startDate.format("YYYY-MM-DD"),
  //       endDate: endDate.format("YYYY-MM-DD"),
  //     });
  //     // Update the Redux store
  //     dispatch(setOrderFilter(`orderdate[$lte]=${endDate.format("YYYY-MM-DD")}&orderdate[$gte]=${startDate.format("YYYY-MM-DD")}`));
  //   } else {
  //     alert("Please select both a start date and an end date.");
  //   }
  //   handleClose();
  // };
  const handleApply = () => {
    if (startDate && endDate) {
      onApply({
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
      });

      // Get the current orderFilter from the Redux store
      let currentOrderFilter = orderDateNewFilter;

      // Remove the existing date filters from the currentOrderFilter
      let newOrderFilter = currentOrderFilter.replace(
        /orderdate\[\$lte\]=.*?&/g,
        ""
      );
      newOrderFilter = newOrderFilter.replace(/orderdate\[\$gte\]=.*?&/g, "");

      // Check if the newOrderFilter string ends with an ampersand
      if (!newOrderFilter.endsWith("&")) {
        newOrderFilter += "&";
      }

      // Append the new date filters to the newOrderFilter
      newOrderFilter += `orderdate[$lte]=${endDate.format(
        "YYYY-MM-DD"
      )}&orderdate[$gte]=${startDate.format("YYYY-MM-DD")}`;

      // Update the Redux store with the newOrderFilter
      dispatch(setOrderFilter(newOrderFilter));
    } else {
      alert("Please select both a start date and an end date.");
    }
    handleClose();
  };

  const handleClear = () => {
    // Clear the dates
    setStartDate(null);
    setEndDate(null);

    // Get the current orderFilter from the Redux store
    let currentOrderFilter = orderDateNewFilter;

    // Remove the date filters from the currentOrderFilter
    let newOrderFilter = currentOrderFilter.replace(
      /orderdate\[\$lte\]=.*?(&|$)/g,
      ""
    );
    newOrderFilter = newOrderFilter.replace(/orderdate\[\$gte\]=.*?(&|$)/g, "");

    // Update the Redux store with the newOrderFilter
    dispatch(setOrderFilter(newOrderFilter));

    handleClose();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Badge
        badgeContent={
          orderDateNewFilter &&
          (orderDateNewFilter.includes("orderdate[$lte]") ||
            orderDateNewFilter.includes("orderdate[$gte]"))
            ? "1"
            : null
        }
        sx={{
          "& .MuiBadge-badge": {
            color: "#FFF",
            backgroundColor: "red",
            zIndex: "0",
            fontSize: "12px",
          },
        }}
      >
        <SmallButton
          background="#fff"
          color="#163e6e"
          onClick={handleClick}
          label={filterName}
          endIcon={
            anchorEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
          }
        />
      </Badge>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ marginTop: "10px" }}
      >
        <MenuItem>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
            slotProps={{
              textField: { size: "small" },
            }}
            renderInput={(params) => <TextField {...params} />}
            format="YYYY-MM-DD"
          />
        </MenuItem>
        <MenuItem>
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
            slotProps={{ textField: { size: "small" } }}
            renderInput={(params) => <TextField {...params} />}
            format="YYYY-MM-DD"
          />
        </MenuItem>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {/* <MenuItem>
          <SmallButton width="100%" label="Apply" onClick={handleApply}/>
        </MenuItem> */}
          {/* <MenuItem sx={{width:"100%"}}> */}
          <div className="flex flex-row items-center justify-end py-2 mx-4  rounded-md w-full">
            <div
              onClick={handleApply}
              className="flex flex-row items-center justify-center  p-1 px-2 border-2 rounded-md cursor-pointer"
              style={{
                color: "#163e6e",
                cursor: "pointer",
                fontFamily: '"Outfit", sans-serif',
                fontWeight: 500,
              }}
            >
              <CheckIcon
                sx={{
                  width: "20px",
                  height: "20px",
                  color: "#163e6e",
                  marginRight: "2px",
                  fontWeight: 600,
                }}
              />
              Apply
            </div>
            <div
              onClick={handleClear}
              className="flex flex-row items-center justify-center mx-1 p-1 px-2 border-2 rounded-md cursor-pointer"
              style={{
                color: "red",
                cursor: "pointer",
                fontFamily: '"Outfit", sans-serif',
                fontWeight: 500,
              }}
            >
              <ClearIcon
                sx={{
                  width: "20px",
                  height: "20px",
                  color: "red",
                  marginRight: "2px",
                  fontWeight: 600,
                }}
              />
              Clear
            </div>
          </div>
          {/* </MenuItem> */}
          {/* <SmallButton width="100%" label="Clear" background="red" color="#fff" onClick={handleClear}/> */}
        </div>
      </Menu>
    </LocalizationProvider>
  );
};

export default DateRangeFilter;

// {"filter":"{\"brandFilter\":\"\",\"productFilter\":\"\",\"marqfluencerFilter\":\"\",\"webMarqfluencerFilter\":\"\",\"webBrandFilter\":\"\",\"campaignFilter\":\"\",\"orderFilter\":\"status=65e89f03dbcc28351f04606corderdate[$lte]&orderdate[$gte]=2024-04-18\",\"collabFilter\":\"\",\"paymentFilter\":\"\",\"reimbursedFilter\":\"\"}","_persist":"{\"version\":-1,\"rehydrated\":true}"}

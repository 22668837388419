import React, { useState, useEffect } from "react";
import {
  Grid,
  FormControl,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  InputAdornment,
  IconButton,
  Card,
  CardContent,
} from "@mui/material";
import { PATTERNS } from "../validetion";
import { validateInput } from "../validetionUtility";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";
import {
  patchFormData,
  postFormData,
  postJsonData,
} from "../../Services/AxoisInterceptorr";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { MarqetingLogoSidebar } from "../IconsImport";
import { useUser } from "../../contexts/UserDataStore";
import BorderColorIcon from "@mui/icons-material/BorderColor";
const Register = () => {
  const [isEmail, setIsEmail] = useState(false);
  const [email, setEmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [otpEntryHidden, setotpEntryHidden] = useState(true);
  const [timer, setTimer] = useState(30);
  const [showPasswordToggle, setShowPasswordToggle] = useState("");

  const [formData, setFormData] = useState({
    fname: {
      value: "",
      isValid: true,
    },
    phone: {
      value: "",
      isValid: true,
    },
    password: {
      value: "",
      isValid: true,
    },
    otp: {
      value: "",
      isValid: true,
    },
    referral: {
      value: "",
      isValid: true,
    },
  });
  const { setUser } = useUser();
  const [openOtpField, setOpenOtpField] = useState(false);
  const [openSendOtp, setOpenSendOtp] = useState(false);
  const [phoneNumberDisabled, setPhoneNumberDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingOtp, setloadingOtp] = useState(false);
  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    // const isValid = regEx ? validateInput(value, regEx) : true;
    const isValid = value.trim() === "" || validateInput(value, regEx);
    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
  };

  const navigate = useNavigate();

  useEffect(() => {
    let intervalId;

    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timer]);
  const handleClickShowPassword = () => {
    setShowPasswordToggle(!showPasswordToggle);
  };

  const handleForgetSendOtp = () => {
    const isFormValid =
      formData.phone.isValid && formData.phone.value.trim() !== "";

    if (isFormValid && !loadingOtp) {
      setloadingOtp(true)
      const forgetData = {
        mobileNumber: formData.phone.value,
        role:"InfluencerRegister"
      };

      postJsonData(
        VisitorEndpoint.SEND_OTP,
        forgetData,
        () => {},
        (res) => {
          if (res && res.status === 200) {
            toast.success(res.data.message);
          }
          setOpenOtpField(true);
          setOpenSendOtp(true);
          setPhoneNumberDisabled(true);
          setloadingOtp(false)
        },
        (error) => {
          toast.error(error.response.data.message);
          setOpenOtpField(false);
          setOpenSendOtp(false);
          setloadingOtp(false)
        }
      );
    } else {
      console.log("Form data is not valid.");
      setOpenOtpField(false);
      setloadingOtp(false)
    }
  };
  const handleForgetReSendOtp = () => {
    setTimer(30);
    const isFormValid =
      formData.phone.isValid && formData.phone.value.trim() !== "";

    if (isFormValid) {
      const forgetData = {
        mobileNumber: formData.phone.value,
      };

      postJsonData(
        VisitorEndpoint.RE_SEND_OTP,
        forgetData,
        () => {},
        (res) => {
          if (res && res.status === 200) {
            toast.success(res.data.message);
          }
          // setOpenOtpField(true)
          // setOpenSendOtp(true)
        },
        (error) => {
          toast.error(error.response.data.message);
          // setOpenOtpField(false)
          // setOpenSendOtp(false)
        }
      );
    } else {
      console.log("Form data is not valid.");
      setOpenOtpField(false);
    }
  };
  const handleRegisterPassword = (e) => {
    e.preventDefault();
    // if (formData.fname.isValid && formData.phone.value) {
    //  toast.error("Please verify your number.");
    //   return;
    // }
    const isFormValid =
  
      formData.phone.isValid &&
      formData.password.isValid &&
      formData.fname.value.trim() !== "" &&
      formData.phone.value.trim() !== "" &&
    formData.password.value.trim() !== "";
    if (isFormValid && !loading) {
      setLoading(true);
      const forgetData = {
        influencerName: formData.fname.value,
        mobileNumber: formData.phone.value,
        pin: formData.password.value,
        otp: formData.otp.value,
        referralCode: formData.referral.value,
      };

      postJsonData(
        VisitorEndpoint.INFLUENCER_REGISTER,
        forgetData,
        () => {},
        (res) => {
          if (res && res.status === 201) {
            toast.success(res.data.message);
            navigate("/influencer/login");
          }
          setLoading(false);
        },
        (error) => {
          if (error?.response?.status === 409) {
            setPhoneNumberDisabled(false);
            setOpenOtpField(false);
            setOpenSendOtp(false)
            formData.otp.value=""
            formData.password.value=""
          }
          toast.error(error.response.data.message);
          setLoading(false);
      }
      
      );
    } else {
      toast.error("Please verify your number.");
      setLoading(false);
    }
  };
  const handleEditPhoneNumber = () => {
    setPhoneNumberDisabled(false);
    setOpenOtpField(false);
    setOpenSendOtp(false);
  };

  return (
    <>
      <section className="bg-primary bg-cover">
        <div className="flex flex-col min-h-screen items-center justify-center px-6 py-8 mx-auto  lg:py-0">
          <div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0">
            <a
              href="#"
              className="flex items-center justify-center mb-0 mt-10 text-2xl font-semibold text-gray-900"
            >
              <img
                className="w-[200px] h-[50px] mr-2"
                src={MarqetingLogoSidebar}
                alt="logo"
              />
            </a>
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8 sm:pt-0">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
              Register
              </h1>
              <form
                className="space-y-4 md:space-y-6"
                onSubmit={handleRegisterPassword}
              >
                   <div>
                  <label
                    for="name"
                    class="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    name="fname"
                    id="fname"
                    placeholder="Enter Full Name"
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
                    required
                    onChange={(e) => {
                        setValues(e, "fname", null);
                      }}
                  />
                </div>

                <div>
                  <div className="flex flex-row justify-between text-right rounded-lg bg-white">
                    <label
                      for="phone"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Mobile Number
                    </label>
                    {/* <div
                      className="align_self cursor-pointer"
                      onClick={handleEditPhoneNumber}
                    >
                      <h5 className="text-gray-600 underline">Edit Number</h5>
                    </div> */}
                  </div>
                  <div className="flex flex-row text-right rounded-lg bg-white">
                    <input
                      type="number"
                      name="phone"
                      id="phone"
                      className="bg-gray-50 border  text-gray-900 sm:text-sm rounded-l-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 disabled:cursor-not-allowed "
                      placeholder="Enter Number"
                      required
                      onChange={(e) => {
                        setValues(e, "phone", PATTERNS.MOBILE);
                      }}
                      disabled={phoneNumberDisabled}
                    />
                    {openSendOtp && (
                      <button
                        type="button"
                        // disabled={openSendOtp}
                        className="w-5 flex items-center justify-center text-primary border-[1px] border-gray border-l-0 rounded-l-none   bg-white rounded-l-0 text-sm px-5 py-2"
                        onClick={handleEditPhoneNumber}
                      >
                        <BorderColorIcon
                          sx={{ width: "18px", height: "18px" }}
                        />
                      </button>
                    )}

                    {openSendOtp ? (
                      <button
                        type="button"
                        // disabled={openSendOtp}
                        className="w-40 text-primary border-[1px] border-gray border-l-0 rounded-l-none   bg-white hover:bg-primary-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:bg-gray-400 text-gray"
                        onClick={handleForgetReSendOtp}
                        disabled={timer > 0}
                      >
                        Resend
                      </button>
                    ) : (
                      <button
                        type="button" 
                        disabled={loadingOtp} 
                        className="w-40 text-primary border-[1px] border-gray border-l-0 rounded-l-none   bg-white hover:bg-primary-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:bg-gray-400 text-gray"
                        onClick={handleForgetSendOtp}
                      >{loadingOtp ? "Loading..." : "Send OTP"}
                        
                      </button>
                    )}
                  </div>
                  {formData.phone.isValid || (
                    <p className="input_text_error">
                      *Please enter your valid phone number.
                    </p>
                  )}
                  {openSendOtp && timer > 0 && (
                    <span className="timerresendtext text-black-400 flex justify-end">
                      Resend in&nbsp; <h3 className="text-red-600">{timer}</h3>
                      &nbsp;sec
                    </span>
                  )}
                </div>
                {openOtpField && (
                  <>
                    {" "}
                    <div>
                      <label
                        for="password"
                        class="block mb-2 text-sm font-medium text-gray-900"
                      >
                        OTP
                      </label>
                      <input
                        type="number"
                        name="password"
                        id="password"
                        placeholder="Enter OTP"
                        // disabled
                        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 disabled:bg-slate-200 disabled:cursor-not-allowed"
                        required
                        onChange={(e) => {
                          setValues(e, "otp", null);
                        }}
                      />
                    </div>
                    <div>
                      <label
                        for="password"
                        class="block mb-2 text-sm font-medium text-gray-900 "
                      >
                        Password
                      </label>
                      <div className="flex flex-col">
                        <div className="flex flex-row rounded-lg bg-white">
                          <input
                            type={showPasswordToggle ? "text" : "password"}
                            name="password"
                            id="password"
                            placeholder="Enter Password"
                            // disabled
                            class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-l-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 disabled:bg-slate-200 disabled:cursor-not-allowed"
                            required
                            onChange={(e) => {
                              setValues(e, "password", PATTERNS.PASSWORD);
                            }}
                          />

                          <div class=" flex justify-center items-center w-20 border border-gray-300 border-l-0 rounded-r-lg rounded-l-none   bg-white hover:bg-primary-700 ">
                            <InputAdornment>
                              <IconButton
                                sx={{ color: "#163e6e" }}
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                              >
                                {showPasswordToggle ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          </div>
                        </div>
                        {formData.password.isValid || (
                          <>
                          <p className="input_text_error">
    *Password must have at least 8 characters that include at least 1 lowercase character "a-z", 1 uppercase character "A-Z", 1 number "0-9", and 1 special character /~`!@#$%^&amp;*()-_+={}[]|\\;:&lt;&gt;./?\\.
  </p>
                          </>
                        )}
                      </div>
                    </div>
                    <div>
                      <label
                        for="password"
                        class="block mb-2 text-sm font-medium text-gray-900"
                      >
                        Referral Code(Optional )
                      </label>
                      <input
                        type="text"
                        name="referral"
                        id="referral"
                        placeholder="Enter Referral Code"
                        // disabled
                        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 disabled:bg-slate-200 disabled:cursor-not-allowed"
                        onChange={(e) => {
                          setValues(e, "referral", null);
                        }}
                      />
                    </div>
                  </>
                )}
                {/* <div class="flex items-center justify-between">
                  <div class="flex items-start">
                    <div class="flex items-center h-5">
                      <input
                        id="remember"
                        aria-describedby="remember"
                        type="checkbox"
                        class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300"
                        required=""
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label
                        for="remember"
                        class="text-gray-500 "
                      >
                        Resend
                      </label>
                    </div>
                  </div>
                  <a
                    href="#"
                    class="text-sm font-medium text-primary-600 hover:underline"
                  >
                    Resend OTP
                  </a>
                </div> */}
                <button
                  type="submit"                   disabled={loading || !formData.password.isValid}
  
                  class="w-full text-white bg-primary hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"

                >{loading ? "Loading..." : "Register"}
                  
                </button>
                <p class="text-sm font-light text-black-200 ">
                  Already have an account?{" "}
                  <a
                    onClick={() => navigate("/influencer/login")}
                    class="font-medium cursor-pointer text-primary-600 hover:underline "
                  >
                    Login
                  </a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;

import React from "react";
import Modal from "@mui/material/Modal";
import { Box, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SmallButton } from "../coustombutton/SmallButton";

const OrderSuccessPopup = ({ open, navigateToHome }) => {
  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          //   width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
    
          width: { xs: "90%", sm: "90%", md: "400px" },

          background: "#FFFFFF",
          padding: "30px",
          borderRadius: "20px",
          outline: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <CloseIcon
            onClick={navigateToHome}
            sx={{
              color: "red",
              width: "30px",
              height: "30px",
              cursor: "pointer",
            }}
          />
        </div>
        <Grid
          container
          spacing={2}
          sx={{ marginBottom: "20px", marginTop: "20px" }}
        >
          <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
            <h1 className="registerpopup_head">
            Awesome! Your order has been successfully added
               
            </h1>
            <p className="registerpopup_subhead" style={{color:"red"}}>
             Please submit your review within 10 days of receiving your order; otherwise, the order will not be eligible for reimbursement.
              
            </p>
        
            <SmallButton
              onClick={navigateToHome}
              label="Go to Orders"
              width="100%"
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default OrderSuccessPopup;

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const BootstrapTooltip = styled(({ className, tooltipContent, children, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} title={tooltipContent}>
    {children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: 14,
    fontFamily: '"Outfit", sans-serif',
  },
}));

export default function DynamicBootstrapTooltip({ tooltipContent, children }) {
  return (
    <BootstrapTooltip tooltipContent={tooltipContent}>
      {children}
    </BootstrapTooltip>
  );
}

import React, { useEffect, useState } from "react";
import CampaignIcon from "@mui/icons-material/Campaign";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import {
  Avatar,
  Popover,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";

import Sidebar from "../sidebar/Sidebar.js";
import {
  DashboardSvg,
  Marqetinglogowhite,
  Orderwhite,
} from "../IconsImport.js";
import ImageIcon from "../CoustomImage.jsx";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import { useDispatch } from "react-redux";
import { resetFilter } from "../../store/FilterSlice.js";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import RateReviewIcon from "@mui/icons-material/RateReview";
import CryptoJS from "crypto-js";
import { postJsonData } from "../../Services/AxoisInterceptorr.js";
import VisitorEndpoint from "../../Services/VisitorEndpoint.js";
import { toast } from "react-toastify";
import AddShoppingCartSharpIcon from '@mui/icons-material/AddShoppingCartSharp';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import ReferralCard from "../referral/ReferralCard.jsx";
const MarqinfluncersHeader = () => {
  const dispatch = useDispatch();

  const [logoutAnchorEl, setLogoutAnchorEl] = useState(null);
  const [decryptedRole, setDecryptedRole] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    const encryptedRole = localStorage.getItem("userRole");
    if (encryptedRole) {
      const bytes = CryptoJS.AES.decrypt(
        encryptedRole,
        process.env.REACT_APP_SECRET_KEY
      );
      const decryptedRole = bytes.toString(CryptoJS.enc.Utf8);
      setDecryptedRole(decryptedRole);
    }
  }, []);

  const handleLogoutPopoverOpen = (event) => {
    setLogoutAnchorEl(event.currentTarget);
  };

  const handleLogoutPopoverClose = () => {
    setLogoutAnchorEl(null);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();

  const logoutOpen = Boolean(logoutAnchorEl);

  // const logOut = () => {

  //   localStorage.clear();

  //   dispatch(resetFilter())
  //   if (decryptedRole === "Brand") {
  //     return navigate("/brand/login");
  //   } else if (decryptedRole === "Influencer") {
  //     return navigate("/influencer/login");
  //   }
  // };
  const logOut = () => {
    setLoading(true);
    const data = {};
    postJsonData(
      VisitorEndpoint?.LOGOUT,
      data,
      () => {},
      (res) => {
        if (res && res.status === 200) {
          toast.success(res.data.message);

          // Clear local storage
          localStorage.clear();

          // Reset filter
          dispatch(resetFilter());
          if (decryptedRole === "Brand") {
            navigate("/brand/login");
          } else if (decryptedRole === "Influencer") {
            navigate("/influencer/login");
          }
        }
        setLoading(false);
      },
      (error) => {
        toast.error(error.response.data.message);
        setLoading(false);
      }
    );
  };

  // Example usage, ensure this is inside a component
  const handleLogout = () => {
    logOut();
  };
  return (
    <>
          <ReferralCard open={isModalOpen} handleClose={closeModal} />

      <div className="header_container_fluid">
        <div className="container_new">
          <div className="header_container">
            <div className="header_img">
              <img src={Marqetinglogowhite} alt="logo" />
              {/* <p className="logo_header">marqeting</p> */}
            </div>
            <div className="middle_header">
              <ul>
          
                <li>
                  <NavLink
                    to="/influencer/"
                    activeClassName="active"
                    className="brand_header_nav"
                  >
                    <ImageIcon src={DashboardSvg} alt="Dashboard Icon" />
                    <p>Dashboard</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/influencer/order"
                    activeClassName="active"
                    className="brand_header_nav"
                  >
                    <ImageIcon
                      src={Orderwhite}
                      width={22}
                      height={22}
                      marginRight="6px"
                      alt="Dashboard Icon"
                    />{" "}
                    <p>My Orders</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/influencer/order/add"
                    activeClassName="active"
                    className="brand_header_nav"
                  >
                    <AddShoppingCartSharpIcon
                      sx={{ color: "#ffffff", fontSize: 24 }}
                    />
                    <p>Add Order</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/influencer/review/add"
                    activeClassName="active"
                    className="brand_header_nav"
                  >
                    <RateReviewIcon sx={{ color: "#ffffff", fontSize: 24 }} />
                    <p>Add Review</p>
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    to="/influencer/review/add"
                    activeClassName="active"
                    className="brand_header_nav"
                  >
                    <ConnectWithoutContactIcon sx={{ color: "#ffffff", fontSize: 24 }} />
                    <p>Referral</p>
                  </NavLink>
                </li> */}
              </ul>
            </div>
            <div className="footer_header">
              <ul>
                <li
                  onMouseEnter={handleLogoutPopoverOpen}
                  onMouseLeave={handleLogoutPopoverClose}
                >
                  <Avatar
                    // alt={
                    //   user &&
                    //   user.name.charAt(0).toUpperCase() + user.name.slice(1)
                    // }
                    // src={user?.name}
                    sx={{ width: 28, height: 28, fontSize: 14 }}
                  />
                  <Popover
                    open={logoutOpen}
                    anchorEl={logoutAnchorEl}
                    onClose={handleLogoutPopoverClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <List>
                      <ListItem
                        onClick={() => {
                          handleLogout();
                        }}
                        sx={{
                          fontFamily: '"Outfit", sans-serif',
                          fontWeight: 600,
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#ccc",
                          },
                        }}
                      >
                        {" "}
                        {!loading && (
                          <>
                            <SupervisorAccountIcon
                              sx={{
                                color: "#000000",
                                fontSize: 24,
                                marginRight: "6px",
                              }}
                            />
                            <ListItemText
                              primaryTypographyProps={{
                                style: {
                                  fontFamily: '"Outfit", sans-serif',
                                  fontWeight: 600,
                                },
                              }}
                              primary={loading ? "Logging out" : "Log Out"}
                            />
                          </>
                        )}
                      </ListItem>
                      <ListItem
                    onClick={openModal}
                        sx={{
                          fontFamily: '"Outfit", sans-serif',
                          fontWeight: 600,
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#ccc",
                          },
                        }}
                      >
                      
                            <ConnectWithoutContactIcon
                              sx={{
                                color: "#000000",
                                fontSize: 24,
                                marginRight: "6px",
                              }}
                            />
                            <ListItemText
                              primaryTypographyProps={{
                                style: {
                                  fontFamily: '"Outfit", sans-serif',
                                  fontWeight: 600,
                                },
                              }}
                              primary={"Refer and Earn" }
                            />
                       
                      </ListItem>
                    </List>
                  </Popover>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Sidebar />
    </>
  );
};

export default MarqinfluncersHeader;

import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import MarqinfluncersHeader from "../component/header/MarqinfluncersHeader";
import CryptoJS from "crypto-js";
import BrandHeader from "../component/header/BrandHeader";

const PrivateRoute = ({ role, ...rest }) => {
  const token = localStorage.getItem("mqttoken");
  const userRole = localStorage.getItem("userRole");
  const [decryptedRole, setDecryptedRole] = useState(null);
  // console.log(userRole);

  useEffect(() => {
    const encryptedRole = localStorage.getItem("userRole");
    if (encryptedRole) {
      const bytes = CryptoJS.AES.decrypt(
        encryptedRole,
        process.env.REACT_APP_SECRET_KEY
      );
      const decryptedRole = bytes.toString(CryptoJS.enc.Utf8);
      setDecryptedRole(decryptedRole);
    }
  }, []);
  if (!token) {
    return <Navigate to="/brand/login" />;
  } else if (token && decryptedRole && decryptedRole !== role) {
    return <Navigate to="/unauthorized" />;
  } else if (token && decryptedRole && decryptedRole === "Brand") {
    return (
      <>
        <BrandHeader />
        {rest.element}
      </>
    );
  } else if (token && decryptedRole && decryptedRole === "Influencer") {
    return (
      <>
        <MarqinfluncersHeader />
        {rest.element}
      </>
    );
  }
};

export default PrivateRoute;

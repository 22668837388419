import React, { useEffect, useState } from "react";
import { Box, Button, TablePagination, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTable from "../../component/tabel/DataTabel";
import ShortingTable from "../../component/tabel/ShortingTabel";
import {
  useGetInfluencerAllOrders,
  useGetStatus,
} from "../../component/customHooks/customHook";
import ActiveLastBreadcrumb from "../../component/breadcrumb/ActiveLastBreadcrumb";

import FilterPopupComponent from "../../common/filter/filterPopup";

import { useDispatch, useSelector } from "react-redux";
import { setOrderFilter } from "../../store/FilterSlice";
import HistoryIcon from "@mui/icons-material/History";
import InfluencerorderViewDetails from "../../component/viewdetails/InfluencerorderViewDetails";
import InfluencerNoteHistory from "../../component/adminhistory/InfluencerNoteHistory";
import RateReviewIcon from "@mui/icons-material/RateReview";
import AddReviewPopup from "../../component/adminnote/AddReviewPopup";
import MarqueeText from "../../component/marquee/MarqueeText";

const MarqOrderComponent = () => {
  const dispatch = useDispatch();
  const filterParam = useSelector((state) => state.filter.orderFilter);
  const [openReviewNote, setopenReviewNote] = useState(false);
  const [rowDatanew, setrowDatanew] = useState(null);
  const [rowDeleveryDatanew, setrowDeleveryDatanew] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [influencerDrawerOpen, setInfluencerDrawerOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [perPageLimit, setPerPageLimit] = useState(25);
  const [row, setRow] = useState();
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    startDate: null,
    endDate: null,
  });
  const [historyDrawerOpen, setHistoryDrawerOpen] = useState(false);

  const navigate = useNavigate();
  const columns = [
    { id: "sno", label: "S.No.", minWidth: 30 },
    { id: "orderid", label: "Order Id", minWidth: 200 },
    { id: "influencername", label: "Marqfluencer", minWidth: 100 },
    { id: "brand", label: "Brand Name", minWidth: 100 },
    { id: "product", label: "Product", minWidth: 50 },
    { id: "amount", label: "Amount", minWidth: 30 },
    { id: "reimbursed", label: "Reimbursed", minWidth: 30 },
    { id: "status", label: "Status", minWidth: 80 },
  ];
  const { allstatus, loading, totalPages, totalCount } =
    useGetInfluencerAllOrders(
      currentPage,
      perPageLimit,
      sortField,
      sortOrder,
      filterParam,
      triggerRefresh
    );
  //   const allstatusExport = useGetExportExcelOrders(filterParam, triggerRefresh);
  //   const orderDetailsExcel = allstatusExport?.allstatusExport;

  const status = useGetStatus("", "", "Order");

let newStatus = ['Placed', 'Reviewed',"Reimbursement Pending", 'Reimbursed', 'Pending Review', 'Cancelled', 'Returned', 'Invalid'];

const filteredData = status?.allstatus
  .filter(item => newStatus.includes(item.status))
  .map(item => {
    return {
      _id: item._id,
      status: item.status,
    };
  });
  const realData = allstatus.map((item) => ({
  
    id: item._id,
    orderManager: item.orderManager,
    sno: item.sno,
    brandname: item.brand ? item.brand.brandName : "",
    orderid: item.orderId ? item.orderId : "",
    influencername: item.influencer && item.influencer?.influencerName,
    brand: item.brand && item.brand?.brandName,
    product: item.product ? item.product?.productName : null,
    amount: item.orderValue && item.orderValue,
    reimbursed: item.reimbursed && item.reimbursed,
    status: item.influencerStatus?.status,
  }));
  const { allstatus: newFilterData } = useGetInfluencerAllOrders();

  // console.log(realData);
  // const statuses = realData.map(item => item.status);
  const influencerFilter = newFilterData?.map((item) => ({
    id: item.influencer?._id,
    name: item.influencer?.influencerName,
  }));
  const productData = newFilterData?.map((item) => ({
    id: item.product?._id,
    name: item.product?.productName,
  }));
  const brandData = newFilterData?.map((item) => ({
    id: item.brand?._id,
    name: item.brand?.brandName,
  }));
  const uniqueinfluencerData = influencerFilter.filter(
    (influencer, index, self) =>
      index ===
      self.findIndex(
        (c) => c.id === influencer.id && c.name === influencer.name
      )
  );
  const uniqueBrandData = brandData.filter(
    (brand, index, self) =>
      index ===
      self.findIndex((c) => c.id === brand.id && c.name === brand.name)
  );
  const uniqueProductData = productData.filter(
    (product, index, self) =>
      index ===
      self.findIndex((c) => c.id === product.id && c.name === product.name)
  );
  const orderIdFilter = newFilterData?.map((item) => ({
    orderId: item.orderId,
  }));

  const influencerStatusFilter = newFilterData?.map((item) => ({
    id: item.influencerStatus?._id,
    status: item.influencerStatus?.status,
  }));

  // const uniqueStatusData = influencerStatusFilter.filter(
  //   (influencerStatus, index, self) =>
  //     index ===
  //     self.findIndex(
  //       (c) => c.id === influencerStatus.id && c.name === influencerStatus.name
  //     )
  // );

  let filters = [
    {
      label: "Marqfluencer",
      urlParam: "influencername",
      values: uniqueinfluencerData.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    },
    {
      label: "Product",
      urlParam: "productname",
      values: uniqueProductData.map((item) => ({
        label: item.name && item.name.substring(0, 50),
        value: item.id,
      })),
    },
    {
      label: "Status",
      urlParam: "influencerstatus",
      values: filteredData?.map((item) => ({
        label: item.status,
        value: item._id,
      })),  },

    {
      label: "Brand Name",
      urlParam: "brandname",
      values: uniqueBrandData.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    },
    {
      label: "Order Id",
      urlParam: "orderid",
      values: orderIdFilter?.map((item) => ({
        label: item.orderId,
        value: item.orderId,
      })),
    },
  ];
  const refreshData = () => {
    setTriggerRefresh(!triggerRefresh);
  };

  const setRowFunction = (row) => {
    const selectedData = allstatus.find((item) => item._id === row.id);
    setRow(selectedData);
  };
  const handleRowsPerPageChange = (event) => {
    setPerPageLimit(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  const message = "Note - *Reviews must be submitted within 10 days of order delivery; otherwise, the order will not be eligible for reimbursement."
  const startIndex = (currentPage - 1) * perPageLimit + 1;
  const endIndex = Math.min(startIndex + perPageLimit - 1, totalCount);
  const handleChangePage = (event, newPage) => {
    if (newPage > currentPage - 1 && endIndex < totalCount) {
      setCurrentPage(newPage + 1);
    } else if (newPage < currentPage - 1 && startIndex > 1) {
      setCurrentPage(newPage + 1);
    }
  };

  const setParam = (value) => {
    if (filterParam !== value) {
      dispatch(setOrderFilter(value));
      setCurrentPage(1);
      setTriggerRefresh((prev) => !prev);
    }
  };

  const selectSort = (sortFieldValue, sortOrderValue) => {
    setSortField(sortFieldValue);
    setSortOrder(sortOrderValue);
  };

  const actions = [
    {
      icon: HistoryIcon,

      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
        handleOpenHistoryModal();
      },
    },
    {
      icon: EditIcon,

      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
        navigate("/influencer/order/edit", { state: { selectedData } });
     
      },
    

    },
    {
      icon: RateReviewIcon,
      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
        // setEditState(selectedData);
        setrowDatanew(selectedData?.reviewScreenshot);
        setrowDeleveryDatanew(selectedData?.deliveredScreenshot);
        handleOpenReviewModal();
      },
    },
  ];
  const handleOpenHistoryModal = () => {
    setHistoryDrawerOpen(true);
  };
  const handleOpenReviewModal = () => {
    setopenReviewNote(true);
  };
  const handleReviewCloseModal = () => {
    setopenReviewNote(false);
    setrowDatanew("")
  };
 
  return (
    <>
      <InfluencerorderViewDetails
        influencerDrawerOpen={influencerDrawerOpen}
        setInfluencerDrawerOpen={setInfluencerDrawerOpen}
        rowData={selectedRowData}
      />
      <InfluencerNoteHistory
        historyDrawerOpen={historyDrawerOpen}
        setHistoryDrawerOpen={setHistoryDrawerOpen}
        rowData={selectedRowData}
      />
      <AddReviewPopup
        open={openReviewNote}
        onClose={handleReviewCloseModal}
        row={rowDatanew}
        Deleveryrow={rowDeleveryDatanew}
        editState={selectedRowData}
        endpoint="ADD_ORDER__REVIEW"
        onSuccess={refreshData}
      />
      <div className="container_fluid_new">
       
        <div className="container_new">
        <MarqueeText message={message}/>
          <div>
            <ActiveLastBreadcrumb
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Order", href: "/influencer/order" },
              ]}
            />
          </div>
          <div className="brands_container">
            <div className="brands_content">
              <p>Order</p>
              <span>{totalCount}&nbsp;Total Order</span>
            </div>
          </div>
          {/* <div
            style={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          > */}

          {/* </div> */}
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <FilterPopupComponent
              filters={filters}
              onChange={setParam}
              isOpen={isOpen}
              filterKey="orderFilter"
            />
          </div>
          {/* </div> */}
          <ShortingTable
            columns={columns}
            loading={loading}
            data={realData}
            actions={actions}
            rowFunction={setRowFunction}
            onClickLink={(row) => {
              const selectedData = allstatus.find(
                (item) => item._id === row.id
              );
              setSelectedRowData(selectedData);
              setInfluencerDrawerOpen(true);
            }}
            onClickTooltip={(row) => {
              const selectedData = allstatus.find(
                (item) => item._id === row.id
              );
              // setSelectedRowData(selectedData);
            }}
          />

          <TablePagination
            rowsPerPageOptions={[20, 25, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={perPageLimit}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPageChange}
            labelDisplayedRows={({ from, to, count }) =>
              `${startIndex}-${endIndex} of ${count}`
            }
            sx={{
              fontFamily: '"Outfit", sans-serif',
              fontWeight: 600,
              fontSize: "14px",
              color: "#000",
            }}
          />
        </div>
      </div>
    </>
  );
};
export default MarqOrderComponent;

import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
} from "@mui/material";
import { toast } from "react-toastify";
import { CustomRequiredLabel } from "../../common/styledCcomponents/styledInput";
import { DescriptionInput } from "../../common/styledCcomponents/StyledMenu";
import { validateInput } from "../validetionUtility";
import { SmallButton } from "../coustombutton/SmallButton";
import CloseIcon from "@mui/icons-material/Close";
import { PATTERNS } from "../validetion";

const AddUpiInfluencerPopup = ({
  open,
  onClose,
  onFormSubmit,
  onSuccess,
}) => {

  const [formData, setFormData] = useState({
    upiid: {
      value: "",
      isValid: true,
    },
    holdername: {
      value: "",
      isValid: true,
    },
  });
  const [formSubmitted, setFormSubmitted] = useState(false);


  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    let isValid = true;
    if (regEx) {
      isValid = value.trim() === "" || validateInput(value, regEx);
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
  
  };

  
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    const isFormValid =
    formData.upiid?.value && formData.upiid.isValid && formData.holdername?.value;
    if (isFormValid) {
      const data = {
        upiId: formData.upiid.value,
        holderName: formData.holdername.value,
      };
  
      onFormSubmit(data);
      // toast.success("UPI deatils submitted successfully!");
      onClose();
      onSuccess && onSuccess();
      setFormData({
        upiid: {
          value: "",
          isValid: true,
        },
        holdername: {
          value: "",
          isValid: true,
        },
      });
      // setFormData({});
     
    
    }else {
      console.log("Form data is not valid.");
      setFormData({
        upiid: {
          value: "",
          isValid: true,
        },
        holdername: {
          value: "",
          isValid: true,
        },
      });
    }
  }

  return (
    <>

          <Modal open={open} onClose={onClose}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: {xs:"90%", sm: "90%", md: "400px" },
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                background: "#FFFFFF",
                padding: "50px",
                borderRadius: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <CloseIcon
                  onClick={onClose}
                  sx={{
                    color: "red",
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    marginBottom: "10px",
                  }}
                />
              </div>
              <h2 className="admin_note_heading">
                Add UPI Details
              </h2>
              <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
              <Grid
                container
                spacing={2}
                sx={{ marginBottom: "20px", marginTop: "20px" }}
              >
           
                <Grid item xs={12} sm={12} sx={{ paddingBottom: "5px" }}>
                  <CustomRequiredLabel>Enter UPI Id</CustomRequiredLabel>

                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="name"
                      type="text"
                      placeholder="Enter UPI Id"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "upiid", PATTERNS.UPI_NEW);
                      }}
                    />
                  </FormControl>
                       {formSubmitted && !formData.upiid?.value && (
                            <p className="input_text_error">
                              *Please select UPI id.
                            </p>
                          )}
                       {formData.upiid?.isValid || (
                            <p className="input_text_error">
                              *Please Enter valid UPI id.
                            </p>
                          )}
          
                </Grid>
           
                <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>Enter UPI Holder Name</CustomRequiredLabel>

                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="name"
                      type="text"
                      placeholder="Enter UPI Holder Name"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "holdername", null);
                      }}
                    />
                  </FormControl>
                  {formSubmitted && !formData.holdername?.value && (
                            <p className="input_text_error">
                              *Please select holder name.
                            </p>
                          )}
                  {/* <p className="input_text_error">
                      {error}
                      </p> */}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <SmallButton
                  onClick={handleSubmit}
                  label="Submit"
                  width="100%"
                />
              </Grid>
            </Box>
          </Modal>
    
    </>
  );
};

export default AddUpiInfluencerPopup;

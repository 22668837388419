import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { getAxios } from "../../Services/AxoisInterceptorr";

// charts

// export function useOrderCharts(startDate, endDate) {
//   const [allstatus, setAllstatus] = useState([]);
//   const [loading, setLoading] = useState(true);

//   const fetchBrandCategory = () => {
//     setLoading(true);
//     // const endpoint = VisitorEndpoint.ADMIN_ORDER_CHART
//     //    || VisitorEndpoint.ADMIN_ORDER_CHART +
//     //   `?startdate=${startDate}&enddate=${endDate}`;
//     const endpoint = VisitorEndpoint.ADMIN_ORDER_CHART;
//     if (startDate && endDate) {
//       endpoint += `?startdate=${startDate}&enddate=${endDate}`;
//     }
//     const axiosInstance = getAxios();

//     axiosInstance
//       .get(endpoint)
//       .then((response) => {
//         setLoading(false);
//         setAllstatus(response.data.data[0].orders);
//       })
//       .catch((error) => {
//         // toast.error(error.response.data.message);;
//         setLoading(false);
//       });
//   };

//   useEffect(() => {
//     fetchBrandCategory();
//   }, [startDate, endDate]);

//   return { allstatus, loading };
// }

// export function useGetExportExcelOrders(id, filterParam, triggerRefresh) {
//   const [allstatusExport, setAllstatusExport] = useState([]);
//   const [loading, setLoading] = useState(true);

//   const fetchuseGetCamapign = () => {
//     if (id) {
//       setLoading(true);
//       const endpoint =
//         `${VisitorEndpoint.EXPORT_EXCEL_ORDERS}/${id}` +
//         (filterParam ? `?${filterParam}` : "");
//       const axiosInstance = getAxios();

//       axiosInstance
//         .get(endpoint)
//         .then((response) => {
//           setLoading(false);
//           setAllstatusExport(response.data.data);
//         })
//         .catch((error) => {
//           // toast.error(error.response.data.message);;

//           setLoading(false);
//         });
//     }
//   };

//   useEffect(() => {
//     fetchuseGetCamapign();
//   }, [id, filterParam, triggerRefresh]);
//   return { allstatusExport };
// }

// export function useGetExportExcelOrders(id, filterParam, triggerRefresh) {
//   const [allstatusExport, setAllstatusExport] = useState([]);
//   const [loading, setLoading] = useState(true);

//   const fetchuseGetCamapign = () => {
//     if (id && shouldFetch) {
//       setLoading(true);
//       const endpoint =
//         `${VisitorEndpoint.EXPORT_EXCEL_ORDERS}/${id}` +
//         (filterParam ? `?${filterParam}` : "");
//       const axiosInstance = getAxios();

//       axiosInstance
//         .get(endpoint)
//         .then((response) => {
//           setLoading(false);
//           setAllstatusExport(response.data.data);
//           setShouldFetch(false);
//         })
//         .catch((error) => {
//           // toast.error(error.response.data.message);;
//           setLoading(false);
//         });
//     }
//   };

//   useEffect(() => {
//     fetchuseGetCamapign();
//   }, [id, filterParam, triggerRefresh, shouldFetch]);

//   return { allstatusExport };
// }

// brand data apis

export function useGetCampaignByOrderPlateformName(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_CAMPAIGN_BY_ORDER_PLATFORMNAME}/${id}?type=Brand`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}
export function useGetBrandCampaign(
  id,
  currentPage,
  perPageLimit,
  sortField,
  sortOrder,
  filterParam,
  triggerRefresh
) {
  const [allstatus, setAllstatus] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchuseGetBrand = () => {
    if (id) {
      setLoading(true);
      const endpoint =
        `${VisitorEndpoint.BRAND_ALL_CAMPAIGN}/${id}` +
        `?currentpage=${currentPage}&limit=${perPageLimit}&sortField=${
          sortField || ""
        }&sortOrder=${sortOrder}&${filterParam}`;
      const axiosInstance = getAxios();

      axiosInstance
        .get(endpoint)
        .then((response) => {
          setLoading(false);
          setTotalPages(response.data.data.totalPages);
          setTotalCount(response.data.data.totalCount);
          setAllstatus(response.data.data.data);
        })
        .catch((error) => {
          // toast.error(error.response.data.message);;
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchuseGetBrand();
  }, [
    currentPage,
    perPageLimit,
    sortField,
    sortOrder,
    filterParam,
    triggerRefresh,
    id,
  ]);

  return { allstatus, totalPages, loading, totalCount };
}
export function useGetBrandAllOrders(
  id,
  currentPage,
  perPageLimit,
  sortField,
  sortOrder,
  filterParam,
  triggerRefresh
) {
  const [allstatus, setAllstatus] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchuseGetBrand = () => {
    if (id) {
      setLoading(true);
      const endpoint =
        `${VisitorEndpoint.BRAND_ALL_ORDER}/${id}` +
        `?currentpage=${currentPage}&limit=${perPageLimit}&sortField=${
          sortField || ""
        }&sortOrder=${sortOrder}&${filterParam}`;
      const axiosInstance = getAxios();

      axiosInstance
        .get(endpoint)
        .then((response) => {
          setLoading(false);
          setTotalPages(response.data.data.totalPages);
          setTotalCount(response.data.data.totalCount);
          setAllstatus(response.data.data.data);
        })
        .catch((error) => {
          // toast.error(error.response.data.message);;
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchuseGetBrand();
  }, [
    currentPage,
    perPageLimit,
    sortField,
    sortOrder,
    filterParam,
    triggerRefresh,
    id,
  ]);

  return { allstatus, totalPages, loading, totalCount };
}
export function useGetCampaignStatsforBrand(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.BRAND_CAMPAIGN_STATS}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}
export function useGetAllStatsBrand(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    if (id) {
      setLoading(true);
      const endpoint = `${VisitorEndpoint.BRAND_DASHBOARD}/${id}`;
      const axiosInstance = getAxios();

      axiosInstance
        .get(endpoint)
        .then((response) => {
          setLoading(false);
          setAllstatus(response.data.data);
        })
        .catch((error) => {
          // toast.error(error.response.data.message);;
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, [id]);

  return { allstatus, loading };
}
export function useGetAllOrdersBrandDashboard(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    if (id) {
      setLoading(true);
      const endpoint = `${VisitorEndpoint.BRAND_DASHBOARD_ALLORDERS}/${id}`;
      const axiosInstance = getAxios();

      axiosInstance
        .get(endpoint)
        .then((response) => {
          setLoading(false);
          setAllstatus(response.data.data);
        })
        .catch((error) => {
          // toast.error(error.response.data.message);;
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, [id]);

  return { allstatus, loading };
}
export function useGetAllOrdersByProductBrandDashboard(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    if (id) {
      setLoading(true);
      const endpoint = `${VisitorEndpoint.BRAND_DASHBOARD_PRODUCTCOUNT}/${id}`;
      const axiosInstance = getAxios();

      axiosInstance
        .get(endpoint)
        .then((response) => {
          setLoading(false);
          setAllstatus(response.data.data);
        })
        .catch((error) => {
          // toast.error(error.response.data.message);;
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, [id]);

  return { allstatus, loading };
}
export function useGetAllOrdersByPlatformBrandDashboard(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    if (id) {
      setLoading(true);
      const endpoint = `${VisitorEndpoint.BRAND_DASHBOARD_PLATFORMCOUNT}/${id}`;
      const axiosInstance = getAxios();

      axiosInstance
        .get(endpoint)
        .then((response) => {
          setLoading(false);
          setAllstatus(response.data.data);
        })
        .catch((error) => {
          // toast.error(error.response.data.message);;
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, [id]);

  return { allstatus, loading };
}

export function useCampaignOrderCharts(id, startDate, endDate) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchBrandCategory = () => {
    setLoading(true);
    // const endpoint = VisitorEndpoint.ADMIN_ORDER_CHART
    //    || VisitorEndpoint.ADMIN_ORDER_CHART +
    //   `?startdate=${startDate}&enddate=${endDate}`;
    const endpoint =
      `${VisitorEndpoint.CAMPAIGN_ORDER_CHART}/${id}?type=Brand` +
      `&noofdays=10`;
    if (startDate && endDate) {
      endpoint += `?startdate=${startDate}&enddate=${endDate}`;
    }
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data[0].orders);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchBrandCategory();
  }, [startDate, endDate, id]);

  return { allstatus, loading };
}
export function useGetCampaignByOrderProductName(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_CAMPAIGN_BY_ORDER_PRODUCTNAME}/${id}?type=Brand`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}

export function useGetCampaignSatgeDetails(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_CAMPAIGN_BY_ID}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}

export function useGetStatus(
  currentPage,
  perPageLimit,
  statusGroup,
  triggerRefresh
) {
  const [allstatus, setAllstatus] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetStatus = () => {
    setLoading(true);
    const endpoint =
      VisitorEndpoint.STATUS_LIST +
      `?currentpage=${currentPage}&limit=${perPageLimit}&statusgroup=${statusGroup}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setTotalPages(response.data.data.totalPages);
        setTotalCount(response.data.data.totalCount);
        setAllstatus(response.data.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetStatus();
  }, [currentPage, perPageLimit, triggerRefresh]);

  return { allstatus, totalPages, loading, totalCount };
}

// influencers api

export function useGetInfluencerAllOrders(
  currentPage,
  perPageLimit,
  sortField,
  sortOrder,
  filterParam,
  triggerRefresh
) {
  const [allstatus, setAllstatus] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchuseGetBrand = () => {
    setLoading(true);
    const endpoint =
      `${VisitorEndpoint.INFLUENCER_DATA_ALLORDERS}` +
      `?currentpage=${currentPage}&limit=${perPageLimit}&sortField=${
        sortField || ""
      }&sortOrder=${sortOrder}&${filterParam}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setTotalPages(response.data.data.totalPages);
        setTotalCount(response.data.data.totalCount);
        setAllstatus(response.data.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetBrand();
  }, [
    currentPage,
    perPageLimit,
    sortField,
    sortOrder,
    filterParam,
    triggerRefresh,
  ]);

  return { allstatus, totalPages, loading, totalCount };
}
export function useGetInfluencerAllOrdersForReview(triggerRefresh) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchuseGetBrand = () => {
    setLoading(true);
    const endpoint =
      `${VisitorEndpoint.INFLUENCER_DATA_ALLORDERS}` +
      `?influencerstatus=6639fa774f79c6ce0b9eec54`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);

        setAllstatus(response.data.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetBrand();
  }, [triggerRefresh]);

  return { allstatus, loading };
}

export function useGetAllStatsInfluencers() {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = VisitorEndpoint.INFLUENCER_DASHBOARD_STATS;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}

export function useGetAddOrderInfluencers() {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = VisitorEndpoint.INFLUENCER_ADD_ORDER_FILL;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // console.log(error)
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}


export function useGetEditOrderCampaignName(brandid, productid, platformid,newloading) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);


  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.INFLUENCER_ADD_ORDER_CAMAPIGN_NAME}?brand=${brandid}&product=${productid}&campaignplatform=${platformid}`;
    const axiosInstance = getAxios();
  
    axiosInstance
      .get(endpoint)
      .then((response) => {
        setError(false)
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // setAllstatus([])
        setError(true)
        toast.error(error.response.data.message);
        setLoading(false);
      });
  };

  
  useEffect(() => {
    if (brandid && productid && platformid && newloading) {
      fetchuseGetPlatform();
    }
  }, [brandid, productid, platformid,newloading]);

  return { allstatus, loading ,error};
}
export function useGetAddOrderCampaignName(brandid, productid, platformid) {  
  const [allstatus, setAllstatus] = useState([]);  
  const [loading, setLoading] = useState(false);  
  const [campaignError, setCampaignError] = useState(null); // Initialize to null  

  const fetchuseGetPlatform = () => {  
    setLoading(true);  
    const endpoint = `${VisitorEndpoint.INFLUENCER_ADD_ORDER_CAMAPIGN_NAME}?brand=${brandid}&product=${productid}&campaignplatform=${platformid}`;  
    const axiosInstance = getAxios();  

    axiosInstance  
      .get(endpoint)  
      .then((response) => {  
        setLoading(false);  
        setAllstatus(response.data.data || []); // Ensure fallback to empty array  
        setCampaignError(null); // Clear any previous error  
      })  
      .catch((error) => {  
        setAllstatus([]); // Clear status on error  
        setCampaignError(error.response.data.message || "An error occurred."); // Fallback error message  
        // toast.error(error.response.data.message || "An error occurred.");  
        setLoading(false);  
      });  
  };  

  useEffect(() => {  
    if (brandid && productid && platformid) {  
      fetchuseGetPlatform();  
    }  
  }, [brandid, productid, platformid]);  

  return { allstatus, loading, campaignError }; // Return from the hook  
}

export function useGetInfluencer( triggerRefresh) {
  const [allstatusExport, setAllstatusExport] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetCamapign = () => {
    // if (id) {
      setLoading(true);
      const endpoint =
        VisitorEndpoint.INFLUENCER_GET;
      const axiosInstance = getAxios();

      axiosInstance
        .get(endpoint)
        .then((response) => {
          setLoading(false);
          setAllstatusExport(response.data.data);
        })
        .catch((error) => {
          // toast.error(error.response.data.message);;

          setLoading(false);
        });
    }
  // };

  useEffect(() => {
    fetchuseGetCamapign();
  }, [triggerRefresh]);
  return { allstatusExport,loading };
}
export function useGetInfluencerUpi( id,triggerRefresh) {
  const [allstatusExport, setAllstatusExport] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetCamapign = () => {
    if (id) {
      setLoading(true);
      const endpoint =
      `${VisitorEndpoint.INFLUENCER_GET_UPI}/${id}`;
      const axiosInstance = getAxios();

      axiosInstance
        .get(endpoint)
        .then((response) => {
          setLoading(false);
          setAllstatusExport(response.data.data);
        })
        .catch((error) => {
          // toast.error(error.response.data.message);;
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchuseGetCamapign();
  }, [id,triggerRefresh]);
  return { allstatusExport,loading };
}

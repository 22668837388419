import React, { useEffect,useState } from "react";
import { Grid, Typography, CircularProgress } from "@mui/material";
import { NoDataImage } from "../../component/IconsImport";

const NoDataView = ({
  showCampaignMessage = false,
  showCampaignMessageDetails = false,
  loading,
}) => {
  return (
    <>
      {loading ? (
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container hidden={loading}>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <img src={NoDataImage} alt="no_data" width="20%" />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center", mb: "24px" }}>
            <Typography
              variant="h7"
              sx={{
                color: "#163E6E",
                fontFamily: '"Outfit", sans-serif',
                color: "#163e6e",
                fontSize: "16px",
              }}
            >
              You do not have any Record as of now!
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center", mb: "24px" }}>
            {showCampaignMessage && (
              <Typography variant="h7" sx={{ color: "#163E6E" }}>
                There are no campaigns available to view. Please create a new
                campaign first.
              </Typography>
            )}
            {showCampaignMessageDetails && (
              <Typography variant="h7" sx={{ color: "#163E6E" }}>
                Campaign data is missing. Please make sure you have clicked on a
                campaign card to view its details.
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
      <h4 className="fw-bold mt-4" hidden={!loading}>
        {/* <Spinner loading={timer} dots /> */}
        Please Wait . . .
      </h4>
    </>
  );
};

export default NoDataView;

import React, { useEffect, useState } from "react";
import CampaignIcon from "@mui/icons-material/Campaign";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import {
  Avatar,
  Popover,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";

import Sidebar from "../sidebar/Sidebar.js";

import {
  DashboardSvg,
  Marqetinglogowhite,
  Order,
  Orderwhite,
} from "../IconsImport.js";
import ImageIcon from "../CoustomImage.jsx";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import { useDispatch } from "react-redux";
import { resetFilter } from "../../store/FilterSlice.js";
import CryptoJS from "crypto-js";
import { postJsonData } from "../../Services/AxoisInterceptorr.js";
import VisitorEndpoint from "../../Services/VisitorEndpoint.js";
import { toast } from "react-toastify";

const BrandHeader = () => {
  const dispatch = useDispatch();

  const [logoutAnchorEl, setLogoutAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [decryptedRole, setDecryptedRole] = useState(null);
  useEffect(() => {
    const encryptedRole = localStorage.getItem("userRole");
    if (encryptedRole) {
      const bytes = CryptoJS.AES.decrypt(
        encryptedRole,
        process.env.REACT_APP_SECRET_KEY
      );
      const decryptedRole = bytes.toString(CryptoJS.enc.Utf8);
      setDecryptedRole(decryptedRole);
    }
  }, []);
  const [decryptedName, setDecryptedName] = useState(null);
  useEffect(() => {
    const encryptedName = localStorage.getItem("name");
    if (encryptedName) {
      const bytes = CryptoJS.AES.decrypt(
        encryptedName,
        process.env.REACT_APP_SECRET_KEY
      );
      const decryptedName = bytes.toString(CryptoJS.enc.Utf8);
      setDecryptedName(decryptedName);
    }
  }, []);

  const handleLogoutPopoverOpen = (event) => {
    setLogoutAnchorEl(event.currentTarget);
  };

  const handleLogoutPopoverClose = () => {
    setLogoutAnchorEl(null);
  };

  const navigate = useNavigate();

  const logoutOpen = Boolean(logoutAnchorEl);
  const logOut = () => {
    setLoading(true);
    const data = {};
    postJsonData(
      VisitorEndpoint?.LOGOUT,
      data,
      () => {},
      (res) => {
        if (res && res.status === 200) {
          toast.success(res.data.message);

          // Clear local storage
          localStorage.clear();

          // Reset filter
          dispatch(resetFilter());
          if (decryptedRole === "Brand") {
            navigate("/brand/login");
          } else if (decryptedRole === "Influencer") {
            navigate("/influencer/login");
          }
        }
        setLoading(false);
      },
      (error) => {
        toast.error(error.response.data.message);
        setLoading(false);
      }
    );
  };
  // const logOut = () => {

  //   localStorage.clear();
  //   dispatch(resetFilter())
  //   if (decryptedRole === "Brand") {
  //     return navigate("/brand/login");
  //   } else if (decryptedRole === "Influencer") {
  //     return navigate("/influencer/login");
  //   }
  // };

  const handleLogout = () => {
    logOut();
  };
  return (
    <>
      <div className="header_container_fluid">
        <div className="container_new">
          <div className="header_container">
            <div className="header_img">
              <img src={Marqetinglogowhite} alt="logo" />
              {/* <p className="logo_header">marqeting</p> */}
            </div>
            <div className="middle_header">
              <ul>
                <li>
                  <NavLink
                    to="/brand/"
                    activeClassName="active"
                    className="brand_header_nav"
                  >
                    <ImageIcon src={DashboardSvg} alt="Dashboard Icon" />
                    <p>Dashboard</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/brand/campaign"
                    activeClassName="active"
                    className="brand_header_nav"
                  >
                    <CampaignIcon sx={{ color: "#ffffff", fontSize: 22 }} />
                    <p>My Campaigns</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/brand/order"
                    activeClassName="active"
                    className="brand_header_nav"
                  >
                    <ImageIcon
                      src={Orderwhite}
                      width={22}
                      height={22}
                      marginRight="6px"
                      alt="Dashboard Icon"
                    />
                    <p>Orders</p>
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="footer_header">
              <ul>
                <li
                  style={{ listStyleType: "none" }}
                  onMouseEnter={handleLogoutPopoverOpen}
                  onMouseLeave={handleLogoutPopoverClose}
                >
                  <Avatar
                    alt={
                      decryptedName &&
                      decryptedName.charAt(0).toUpperCase() +
                        decryptedName.slice(1)
                    }
                    src={decryptedName}
                    sx={{ width: 28, height: 28, fontSize: 14 }}
                  />
                  <Popover
                    open={logoutOpen}
                    anchorEl={logoutAnchorEl}
                    onClose={handleLogoutPopoverClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <List>
                      <ListItem
                        onClick={() => {
                          handleLogout();
                        }}
                        sx={{
                          fontFamily: '"Outfit", sans-serif',
                          fontWeight: 600,
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#ccc",
                          },
                        }}
                      >
                          {!loading && (
                          <>                        <SupervisorAccountIcon
                          sx={{
                            color: "#000000",
                            fontSize: 24,
                            marginRight: "6px",
                          }}
                        />
                        <ListItemText
                          primaryTypographyProps={{
                            style: {
                              fontFamily: '"Outfit", sans-serif',
                              fontWeight: 600,
                            },
                          }}
                          primary={loading ? "Logging out" : "Log Out"}

                        />
                           </>
                        )}
                      </ListItem>
                    </List>
                  </Popover>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Sidebar />
    </>
  );
};

export default BrandHeader;

// import { Box, Button } from '@mui/material';
// import React from 'react';

// import { useNavigate } from 'react-router-dom';
// import { Pagenotfound } from '../../component/IconsImport';
// import { SmallButton } from '../../component/coustombutton/SmallButton';

// const PageNotFound = () => {
//   const navigate = useNavigate();
//   const userRole = localStorage.getItem("userRole");

//   const handleGoHome = () => {
//     if (userRole === "Admin") {
//     navigate('/brand/');
//      } else if (userRole === "Operations") {
//       navigate("/marqinfluncer/");
//     }  else {
//       navigate('/login');
//     }
//   };

//   return (
//     <Box
//       display="flex"
//       flexDirection="column"
//       justifyContent="center"
//       alignItems="center"
//       height="100vh"
//     >
//       <img src={Pagenotfound} alt="Pagenotfound" />
//       <SmallButton label="Go to Home"  onClick={handleGoHome}/>

//     </Box>
//   );
// };

// export default PageNotFound;
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { SmallButton } from "../../component/coustombutton/SmallButton";

const PageNotFound = () => {
  const navigate = useNavigate();
  const [decryptedRole, setDecryptedRole] = useState(null);
  useEffect(() => {
    const encryptedRole = localStorage.getItem("userRole");
    if (encryptedRole) {
      const bytes = CryptoJS.AES.decrypt(
        encryptedRole,
        process.env.REACT_APP_SECRET_KEY
      );
      const decryptedRole = bytes.toString(CryptoJS.enc.Utf8);
      setDecryptedRole(decryptedRole);
    }
  }, []);
  const handleGoHome = () => {
    if (decryptedRole === "Brand") {
      navigate("/brand/");
    } else if (decryptedRole === "Influncer") {
      navigate("/influencer/");
    } else {
      navigate("/brand/login");
    }
  };
  return (
    <div className="min-h-screen flex flex-col justify-center items-center">
      <div className="text-center">
        <h1 className="font-bold text-4xl">Uh-Oh...</h1>
      </div>
      <div className="mt-8 flex flex-col items-center">
        <p className="text-2xl mt-8 w-[70%] text-gray-700 text-center">
          The page you are looking for may have been moved, deleted, or possibly
          never existed.
        </p>
      </div>
      <div className="mt-6">
        <p className="mt-16 text-8xl text-primary">404</p>
      </div>
      <div className="mt-4">
        <SmallButton label="Go to Home" onClick={handleGoHome} />
      </div>
    </div>
  );
};

export default PageNotFound;
